<template>
  <div>
    <el-row class="title-box" :style="{ borderColor: borderColor }">
      <el-col :span="3" v-for="(item, index) in titleList" :key="index">
        <span :class="tabnum === index ? item.className + 'active' : item.className" @click="activeChange(item, index)">{{
          item.Suggest }} {{ item.Number }} 组</span>
      </el-col>
    </el-row>
    <div v-if="titleList[tabnum] && titleList[tabnum].tabledata">
      <el-table ref="table" :data="titleList[tabnum].tabledata" size="small" v-loading="page.loading"
        tooltip-effect="light" @sort-change="sortChange" row-key="ID" :expand-row-keys="titleList[tabnum].expands"
        @expand-change="expandChange" :header-cell-style="{ background: '#f5f5f5', color: '#606266' }">
        <el-table-column align="center" label="录取概率" sortable="custom" width="100">
          <template slot-scope="scope">
            <span class="RankColor">{{ scope.row.EnrollPercent }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="招生/院校专业">
          <template slot-scope="scope">
            <div>
              <span class="RankColor SchoolNamebox" @click="gotoSchoolDetail(scope.row.SchoolId)">{{ scope.row.SchoolName
              }}</span>

              <template v-if="scope.row.TagNames">
                <el-tag style="margin-left: 10px" size="mini" v-for="item in scope.row.TagNames.split(',')" :key="item">{{
                  item }}</el-tag>
              </template>
            </div>
            <div class="color666">
              <div>
                {{ scope.row.CategoryNames }}/{{ scope.row.SchoolNature }}/{{
                  scope.row.ProvinceName
                }}
              </div>
              <span>代码 {{ scope.row.SchoolCode }}</span>
              <span v-if="scope.row.GroupCode">专业组代码 {{ scope.row.GroupCode }}</span>
              <div v-if="scope.row.Ranking">
                <el-tag size="mini">{{ scope.row.Ranking + '[' + scope.row.RankingType + ']' }}</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="PlanYear + '年招生计划'" width="150">
          <template slot-scope="scope">
            <div>
              <span style="font-weight: bold">{{ scope.row.PlanPersons }}</span>人
            </div>
            <div>{{ scope.row.ExamCondition }}</div>
          </template>
        </el-table-column>

        <el-table-column label="历年录取情况" align="center" width="400">
          <template slot-scope="scope">
            <div class="enroll-item" v-for="(item, index) in enrollShowList(scope.row.EnrollmentList)" :key="index">
              <span>年份：{{ item.Year || '-' }}</span>
              <span v-if="item.subjectType">科目：{{ subjectTypeShow(item) }}</span>
              <span>录取数：{{ item.Persons || '-' }}</span>
              <span>最低分：{{ item.ScoreLow || '-' }}</span>
              <span>最低位次：{{ item.RankLow || '-' }}</span>
            </div>
          </template>
        </el-table-column>

        <!-- 专业 -->
        <el-table-column align="center" label="填报" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="toogleExpand(scope.row)">
              专业 {{ scope.row.NumberOfSpecialty }}
              <i :class="scope.row.flog ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column type="expand" class="list-two" width="1">
          <template slot-scope="scope">
            <el-table :data="scope.row.SpecialtyList" size="small" v-loading="page.loading" tooltip-effect="light"
              @sort-change="sortChange" :show-header="false">
              <el-table-column align="center" label="录取概率" sortable="custom" width="100">
                <template slot-scope="scop">
                  <span class="sub-RankColor">{{ scop.row.EnrollPercent }}%</span>
                </template>
              </el-table-column>
              <el-table-column label="招生专业">
                <template slot-scope="scop">
                  <div>
                    <span class="sub-RankColor SchoolNamebox" @click="
                      gotoSpecialtyDetail(
                        scop.row.SpecialtyCode,
                        scop.row.SpecialtyName
                      )
                      ">{{ scop.row.SpecialtyName }}</span>
                  </div>
                  <div class="color666">代码 {{ scop.row.EnrollmentCode }}</div>
                  <div class="zhuanyetag">
                    <el-tag size="small" v-if="scop.row.LearnSubjectAssessment">学科评估：{{ scop.row.LearnSubjectAssessment
                    }}</el-tag>
                    <el-tag size="small" v-if="scop.row.SpecialtyRank">专业排名：{{ scop.row.SpecialtyRank }}</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="2020年招生计划" width="150">
                <template slot-scope="scop">
                  <div>
                    <span style="font-weight: bold">{{
                      scop.row.PlanPersons
                    }}</span>人
                  </div>
                  <div>
                    <span>{{ scop.row.LearningYears }}年</span>
                    <span v-if="scop.row.LearningCost">¥{{ scop.row.LearningCost }}/年</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="历年录取情况" align="center" width="400">
                <template slot-scope="subScope">
                  <div class="enroll-item" v-for="(subItem, subIndex) in enrollShowList(subScope.row.EnrollmentList)"
                    :key="subItem.SchoolScoreLineId">
                    <span>年份：{{ subItem.Year || '-' }}</span>
                    <span v-if="subItem.subjectType">科目：{{ subjectTypeShow(subItem) }}</span>
                    <span>录取数：{{ subItem.Persons || '-' }}</span>
                    <span>最低分：{{ subItem.ScoreLow || '-' }}</span>
                    <span>最低位次：{{ subItem.RankLow || '-' }}</span>
                  </div>
                </template>
              </el-table-column>


              <el-table-column align="center" label="填报" width="100">
                <template slot-scope="scop">
                  <el-button size="small" :type="scop.row.flog ? 'primary' : ''" @click="handClik(scope.row, scop.row)">{{
                    scop.row.flog ? "已填" : "填报" }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-if="titleList[tabnum] && titleList[tabnum].TotalPage">
      <div class="pagination" v-if="titleList[tabnum].TotalPage > 0 &&
        titleList[tabnum].TotalPage !== titleList[tabnum].pageIndex
        ">
        <el-button v-if="authorization" type="primary" :loading="page.loadingMore"
          @click="handleCurrentChange">查看更多</el-button>
        <div v-else>
          <member-limit text_1="开通" :text_2="nameConfig.WCF"></member-limit>
        </div>
      </div>
    </template>

  </div>
</template>
<script>
import API from '@/api/config'
import {mapGetters} from 'vuex'
// import {
//   NewExamSchoolScoreLineSuggestGroup,
//   NewExamSchoolScoreLine,
//   NewExamSchoolScoreLineSpecialty,
//   NewExamTableHeadYear,
// } from "@/api/newEntrance";
import { getStore } from "@/utils/utils";
import MemberLimit from '@/views/choose-wish/local-components/MemberLimit.vue';

export default {
  components: {
    MemberLimit
  },
  inject: ["getScrollTop", "setScrollTop"],
  data() {
    return {
      hasToken: '',
      // hasZNK: this.$store.state.user.hasZNK,
      // MemberType: this.$store.getters.roles,
      tabnum: 0,
      activeClass: "",
      borderColor: "",
      searchData: {},
      titleList: [],
      tabledata: [],
      dataName: "",
      PlanYear: "",
      //查看参数
      page: {
        loading: false,
        loadingMore: false,
        ProvinceId: "",
        pageIndex: 1,
        pageSize: 10,
        sizes: [10, 50, 100, 300],
        TotalPage: 0,
      },
      titledata: null,
      PercentSort: 0,
      filterlist: [],

      authorization: true,

      wishNumber: {},
      allowBeyondNumber: false
    };
  },
  computed: {
    ...mapGetters(['nameConfig'])
  },
  mounted() {
    // this.getTableData();
    let temp = getStore('LoginData')
    this.hasToken = (temp ? JSON.parse(getStore('LoginData')).Token : '')

    // 权限
    this.$store.dispatch('user/allowMember', ['WCFTB']).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    // 志愿数量提示
    getNumber(value) {
      API.Public.ProvinceWishCount({
        BatchId: value.BatchId,
        ProvinceId: value.ProvinceId,
        EducationLevel: value.EducationLevel
      }).then(res => {
        this.wishNumber = res.data
      })
    },
    toogleExpand(row) {
      let $table = this.$refs.table;
      row.flog = !row.flog;
      $table.toggleRowExpansion(row);
    },
    expandChange(row, expandedRows) {
      this.titleList[this.tabnum].expands = [];
      expandedRows.forEach((ele) => {
        this.titleList[this.tabnum].expands.push(ele.ID);
      });
      // row.flog = !row.flog;
      if (row.flog) {
        this.getSpecialty(row);
      }
    },
    // 获取专业
    getSpecialty(row) {
      API.Wish.NewExamSchoolScoreLineSpecialty({
        SchoolScoreLineId: row.ID,
        ProvinceId: this.searchData.ProvinceId,
        SelectExamSubjects: this.searchData.SelectExamSubjects,
        Score: this.searchData.Score,
        ScoreEqual: this.searchData.ScoreEqual,
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          row.SpecialtyList = res.data.map((item) => {
            item.flog = false;
            item.Code = item.EnrollmentCode;
            item.EnrollmentPercent = item.EnrollPercent;
            return item;
          });
        }
        this.getLsdata();
      });
    },
    sortChange(column, prop, order) {
      if (column.order && column.order === "descending") {
        this.PercentSort = 1;
      } else {
        this.PercentSort = 0;
      }
      this.getList();
    },
    getTableData(val) {
      if (val) {
        this.searchData = val;

        this.getNumber(val)
      }
      API.Wish.NewExamSchoolScoreLineSuggestGroup(this.searchData).then((res) => {
        if (res.data) {
          this.titleList = res.data.map((item, index) => {
            if (index === 0) {
              item.className = "frist";
              item.color = "#66b1ff";
            } else if (index === 1) {
              item.className = "two";
              item.color = "#f8cc3d";
            } else {
              item.className = "last";
              item.color = "#51d629";
            }
            item.tabledata = [];
            item.expands = [];
            item.TotalPage = 0;
            item.pageIndex = 1;
            return item;
          });
          //  if (!this.titledata) {
          //   this.titledata = this.titleList[0];
          // }
          this.getNewExamTableHeadYear();
          this.getList();
        }
      });
    },
    loginDialogActivate() {
      this.$router.push('/login')
    },
    getNewExamTableHeadYear() {
      API.Wish.NewExamTableHeadYear({
        ProvinceId: this.$ls.get("searchData").ProvinceId,
        SelectExamSubjects: this.$ls.get("searchData").SelectExamSubjects,
      }).then((res) => {
        if (res.code === 0) {
          this.PlanYear = res.data.PlanYear;
          this.filterlist = res.data.Years;
          this.dataName = this.filterlist[0];
        }
      });
    },
    getList() {
      this.page.loading = true;
      this.page.pageIndex = 1;
      const data = {
        PercentSort: this.PercentSort,
        PercentBegin: this.titleList[this.tabnum].PercentBegin,
        PercentEnd: this.titleList[this.tabnum].PercentEnd,
        pageIndex: this.page.pageIndex,
        pageSize: this.page.pageSize,
      };
      const searchData = Object.assign(this.searchData, data);
      API.Wish.NewExamSchoolScoreLine(searchData).then((res) => {
        if (res.code === 0) {
          this.titleList[this.tabnum].tabledata = res.data.Data.map((item) => {
            item.flog = false;
            item.SpecialtyList = [];
            item.EnrollmentPercent = item.EnrollPercent;
            return item;
          });
          this.titleList[this.tabnum].TotalPage = res.data.TotalPage;
          this.getLsdata();
        }
        this.page.loading = false;
      });
    },
    getListMore() {
      const height = this.getScrollTop();
      this.page.loadingMore = true;
      const data = {
        PercentSort: this.PercentSort,
        PercentBegin: this.titleList[this.tabnum].PercentBegin,
        PercentEnd: this.titleList[this.tabnum].PercentEnd,
        pageIndex: this.titleList[this.tabnum].pageIndex,
        pageSize: this.page.pageSize,
      };
      const searchData = Object.assign(this.searchData, data);
      API.Wish.NewExamSchoolScoreLine(searchData).then((res) => {
        if (res.code === 0) {
          const tabledata = res.data.Data.map((item) => {
            item.flog = false;
            item.SpecialtyList = [];
            item.EnrollmentPercent = item.EnrollPercent;
            return item;
          });
          this.titleList[this.tabnum].tabledata =
            this.titleList[this.tabnum].tabledata.concat(tabledata);
          this.titleList[this.tabnum].TotalPage = res.data.TotalPage;
          this.getLsdata();
        }
        this.page.loadingMore = false;
        setTimeout(() => {
          this.setScrollTop(height);
        }, 10);
      });
    },
    activeChange(item, index) {
      // this.tabnum = index;
      // this.activeClass = item.className + "active";
      // this.borderColor = item.color;
      // if (this.titleList[this.tabnum].tabledata.length === 0) {
      //   this.getList();
      // }


      if (index !== 0) {

        this.$store.dispatch('user/allowMember', ['WCFTB']).then(flag => {
          if (flag) {

            this.tabnum = index;
            this.activeClass = item.className + "active";
            this.borderColor = item.color;
            if (this.titleList[this.tabnum].tabledata.length === 0) {
              this.getList();
            }
          } else {

            this.$confirm("此功能需要开通"+this.nameConfig.WCF, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.$router.push('/openvip?productCode=WCF')
            }).catch(() => { });
          }
        })

      } else {
        this.tabnum = index;
        this.activeClass = item.className + "active";
        this.borderColor = item.color;
        if (this.titleList[this.tabnum].tabledata.length === 0) {
          this.getList();
        }
      }
    },
    // 数据筛选
    changeBatch(value) {
      this.dataName = value;
    },
    // 加载更多
    handleCurrentChange() {
      this.titleList[this.tabnum].pageIndex += 1;
      this.getListMore();
    },

    // 数据筛选
    filterTag(value, row) {
      this.dataName = value;
      return this.dataName === value;
    },
    handClik(row, row2) {
      if (!this.hasToken) {
        this.loginDialogActivate();
      } else {
        let Arr = this.$ls.get("dataList");
        if (Arr && Arr.length > 0) {
          const index = Arr.findIndex(
            (ele) =>
              // ele.ID === row.ID &&
              ele.GroupCode === row.GroupCode &&
              ele.SchoolCode === row.SchoolCode
          );
          if (index !== -1) {
            if (
              Arr[index].WishSpecialtyList &&
              Arr[index].WishSpecialtyList.length > 0
            ) {
              const index1 = Arr[index].WishSpecialtyList.findIndex(
                (ele) => ele.Code === row2.Code
              );
              if (index1 !== -1) {
                if (Arr[index].WishSpecialtyList.length === 1) {
                  Arr.splice(index, 1);
                } else {
                  Arr[index].WishSpecialtyList.splice(index1, 1);
                }
              } else {

                // 是否可以超过省份标准数量限制
                if(Arr[index].allowSpecialtyBeyondNumber){
                  Arr[index].WishSpecialtyList.push(row2);

                  this.fillWish(Arr)
                }else{
                  if(Arr[index].WishSpecialtyList.length >= this.wishNumber.SpecialtyCount){
                    this.$confirmTips('您填报的数量已超过'+this.wishNumber.SpecialtyCount+'个，是否继续填报？', '注意！', 'warning').then(_=> {
                      Arr[index].WishSpecialtyList.push(row2);
                      Arr[index].allowSpecialtyBeyondNumber = true

                      this.fillWish(Arr)
                    }).catch(_=>{
                      Arr[index].allowSpecialtyBeyondNumber = false

                      this.fillWish(Arr)
                    })
                  }else{
                    Arr[index].WishSpecialtyList.push(row2);

                    this.fillWish(Arr)
                  }
                }
              }
            } else {
              Arr[index].WishSpecialtyList = [];
              Arr[index].WishSpecialtyList.push(row2);

              this.fillWish(Arr)
            }
          } else {
            // 学校数量限制提示

            // 是否可以超过省份标准数量限制
            if(this.allowBeyondNumber){
              codeData()

              this.fillWish(Arr)
            }else{
              if(Arr.length >= this.wishNumber.WishCount){
                this.$confirmTips('您填报的志愿数量已超过'+this.wishNumber.WishCount+'个，是否继续填报？', '注意！', 'warning').then(_=> {
                  codeData()
                  this.allowBeyondNumber = true

                  this.fillWish(Arr)
                }).catch(_=>{
                  this.allowBeyondNumber = false

                  this.fillWish(Arr)
                })
              }else{
                codeData()

                this.fillWish(Arr)
              }
            }
            
            // 添加学校
            function codeData() {
              row.IsTransfer = true;
              row.WishSpecialtyList = [];
              row.WishSpecialtyList.push(row2);
              Arr.push(row);
            }
          }
        } else {
          Arr = [];
          row.IsTransfer = true;
          row.WishSpecialtyList = [];
          row.WishSpecialtyList.push(row2);
          Arr.push(row);
        }

        this.fillWish(Arr)
      }
    },
    // 缓存数据
    fillWish(Arr) {
      this.$ls.set("dataList", Arr);
      this.getLsdata();
      this.$emit("tableList");
    },
    // 按钮状态
    getLsdata() {
      let Arr = this.$ls.get("dataList");
      this.titleList[this.tabnum].tabledata.forEach((item) => {
        item.SpecialtyList.forEach((item2) => {
          if (Arr && Arr.length > 0) {
            const index = Arr.findIndex(
              (ele) =>
                // ele.ID === item.ID &&
                ele.GroupCode === item.GroupCode &&
                ele.SchoolCode === item.SchoolCode
            );
            if (index !== -1) {
              let index1 = -1;
              index1 = Arr[index].WishSpecialtyList.findIndex(
                (ele) => ele.Code === item2.Code
              );
              if (index1 !== -1) {
                item2.flog = true;
              } else {
                item2.flog = false;
              }
            } else {
              item2.flog = false;
            }
          } else {
            item2.flog = false;
          }
        });
      });
    },
    // 院校详情
    gotoSchoolDetail(ID) {
      let routerUrl = this.$router.resolve({
        path: "/university-detail",
        query: { id: ID },
      });
      window.open(routerUrl.href, "_blank");
    },
    // 专业详情
    gotoSpecialtyDetail(ID, Name) {
      let routerUrl = {};
      if (ID && ID.length === 6) {
        routerUrl = this.$router.resolve({
          path: "/specialty-detail",
          query: { SpecialtyCode: ID },
        });
      } else {
        routerUrl = this.$router.resolve({
          path: "/specialty-list",
          query: { CategoryCode: ID, CategoryName: Name },
        });
      }
      window.open(routerUrl.href, "_blank");
    },
    getEnrollment(Arr) {
      let data = null;
      const index = Arr.findIndex(
        (ele) => ele.Year === this.dataName && !ele.SubjectType
      );
      if (index != -1) {
        data = Arr[index];
      }
      return data;
    },
    getEnrollment1(Arr) {
      let data = null;
      const index = Arr.findIndex(
        (ele) => ele.Year === this.dataName && ele.SubjectType === 1
      );
      if (index != -1) {
        data = Arr[index];
      }
      return data;
    },
    getEnrollment2(Arr) {
      let data = null;
      const index = Arr.findIndex(
        (ele) => ele.Year === this.dataName && ele.SubjectType === 2
      );
      if (index != -1) {
        data = Arr[index];
      }
      return data;
    },
    // 历年录取情况列表中，仅展示最近三年的数据
    enrollShowList(list) {
      return list.slice(0, 3)
    },
    // 需要展示科目时，判断文理
    subjectTypeShow(item) {
      return item.subjectType && (item.subjectType === 1 ? '文' : '理')
      // return '文'
    }
  },
};
</script>
<style lang="less" scoped>
.title-box {
  border-bottom: 2px solid rgba(var(--themecolor),0.75);
  text-align: center;
  font-size: 20px;
  padding: 30px 0 10px;

  span {
    cursor: pointer;
  }

  .frist:hover {
    color: rgba(var(--themecolor),0.75);
  }

  .two:hover {
    color: rgba(var(--themecolor),0.75);
  }

  .last:hover {
    color: rgba(var(--themecolor),0.75);
  }
}

.fristactive {
  color: rgba(var(--themecolor),0.75);
}

.twoactive {
  color: rgba(var(--themecolor),0.75);
}

.lastactive {
  color: rgba(var(--themecolor),0.75);
}

.RankColor {
  font-size: 18px;
}

.sub-RankColor {
  font-size: 16px;
  //color: #66b1ff;
}

.color666 {
  color: #666;
}

.SchoolNamebox {
  cursor: pointer;
}

.pagination {
  margin-top: 10px;
  text-align: center;
}

::v-deep .el-table__expanded-cell {
  padding: 0 0 0 0;

  //border: none;
  .el-table__row {
    background-color: rgba(10, 101, 238, 0.15);
  }

  // border-bottom-width: 0px;
}

.commomdiv {
  height: 20px;
}

.enroll-item {
  padding: 5px 10px;
  margin-bottom: 3px;
  background-color: #fefefe;
  box-shadow: 0 0 3px 0 var(--boxShadow);
  border-radius: 2px;

  display: flex;
  justify-content: space-between;
}

::v-deep .el-table tbody tr:hover>td {
  background-color: rgba(186, 195, 210, 0.1);
}
</style>
<style lang="less">
.el-tag{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.1);
  border-color: rgba(var(--themecolor),0.1);
}
.el-table .descending .sort-caret.descending{
  border-top-color: rgba(var(--themecolor),0.75);
}
.el-table .ascending .sort-caret.ascending{
  border-bottom-color: rgba(var(--themecolor),0.75);
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>