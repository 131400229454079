<template>
  <el-dialog title="选择导出列" :visible.sync="dialogVisible" width="50%">
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
      >全选</el-checkbox
    >
    <div style="margin: 15px 0"></div>
    <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
      <el-checkbox
        class="listBox"
        v-for="item in list"
        :label="item.Key"
        :key="item.Key"
        >{{ item.Title }}</el-checkbox
      >
    </el-checkbox-group>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import API from '@/api/config'
export default {
  data() {
    return {
      dialogVisible: false,
      checkAll: false,
      list: [],
      checkedList: [],
      isIndeterminate: true,
    };
  },
  methods: {
    show() {
      this.checkedList = [];
      this.dialogVisible = true;
      this.checkAll = true;
      API.Wish.NewExamScoreLineForecastExportColumnList().then((res) => {
        this.list = res.data;
        this.listAll = [];
        res.data.forEach((ele) => {
          this.listAll.push(ele.Key);
        });
        this.checkedList = this.listAll;
        this.isIndeterminate = false;
      });
    },
    handleOk() {
      if (this.checkedList && this.checkedList.length > 0) {
        this.$emit("handeOk", this.checkedList);
        this.dialogVisible = false;
      } else {
        this.$message.warning("请选择导出列");
      }
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.listAll : [];
      this.isIndeterminate = !this.isIndeterminate;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.list.length;
    },
  },
};
</script>
<style lang="less" scoped>
.listBox {
  line-height: 40px;
}
</style>