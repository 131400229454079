<template>
  <div>
    <div>
      <!-- <el-button
        @click="goBack"
        type="primary"
        size="small"
        icon="el-icon-arrow-left"
        >返回方案列表</el-button
      > -->
      <span class="wishTypeText">
        {{
          $ls.get("searchData").wishType === 0 ? nameConfig.WCF : nameConfig.WDF
        }}
      </span>
      <span class="eldropdown" style="font-weight: bold">{{
        $ls.get("searchData").ProvinceName
      }}</span>
      <span class="eldropdown">我的成绩：{{ seachdata.Score }}分</span>
      <span class="eldropdown">{{ Rank }}位</span>
      <span class="eldropdown">
        <span v-for="(item, index) in seachdata.SelectExamSubjects" :key="index">{{ item.SelectExamTitle ?
          item.SelectExamTitle + ":" : ""
        }}{{
  item.SelectExamSubject.join(",") +
  (index != seachdata.SelectExamSubjects.length - 1 ? "；" : " ")
}}</span></span>
      <el-dropdown class="eldropdown" @command="changeBatch">
        <span class="el-dropdown-link">
          {{ BatchName }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>修改批次</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div style="margin-top: 20px">
      <el-collapse-transition>
        <div class="seach-box" v-show="show3">
          <el-row class="common-box" :gutter="0">
            <el-col :span="2" class="text">
              <span>院校省份:</span>
            </el-col>
            <el-col :span="22">
              <ul class="list-box">
                <li @click="changeactiveId(SchoolProvinceIdList)"
                  :class="SchoolProvinceIdList.length === 0 ? 'liactive' : ''">
                  不限
                </li>
                <li v-for="item in searchList.ProvinceList" :key="item.ID"
                  @click="changeactiveId(SchoolProvinceIdList, item.ID)" :class="getactiveId(SchoolProvinceIdList, item.ID) ? 'liactive' : ''
                    ">
                  {{ item.Name }}
                </li>
              </ul>
            </el-col>
          </el-row>
          <el-row class="common-box" :gutter="0">
            <el-col :span="2" class="text">
              <span>院校类型:</span>
            </el-col>
            <el-col :span="22">
              <ul class="list-box">
                <li @click="changeactiveId(SchoolCategoryIdList)"
                  :class="SchoolCategoryIdList.length === 0 ? 'liactive' : ''">
                  不限
                </li>
                <li v-for="item in searchList.SchoolCategoryList" :key="item.ID"
                  @click="changeactiveId(SchoolCategoryIdList, item.ID)" :class="getactiveId(SchoolCategoryIdList, item.ID) ? 'liactive' : ''
                    ">
                  {{ item.CategoryName }}
                </li>
              </ul>
            </el-col>
          </el-row>
          <el-row class="common-box" :gutter="0">
            <el-col :span="2" class="text">
              <span>热门标签:</span>
            </el-col>
            <el-col :span="13">
              <ul class="list-box">
                <li @click="changeactiveId(SchoolTagList)" :class="SchoolTagList.length === 0 && SchoolNature === null
                  ? 'liactive'
                  : ''
                  ">
                  不限
                </li>
                <li v-for="item in searchList.SchoolNatureList" :key="item.Value" @click="changeNature(item.Value)"
                  :class="SchoolNature === item.Value ? 'liactive' : ''">
                  {{ item.Name }}
                </li>
                <li v-for="item in searchList.SchoolTagList" :key="item.Name"
                  @click="changeactiveId(SchoolTagList, item.Value)" :class="getactiveId(SchoolTagList, item.Value) ? 'liactive' : ''
                    ">
                  {{ item.Name }}
                </li>
              </ul>
            </el-col>
            <el-col :span="2" class="text">
              <span>分数范围:</span>
            </el-col>
            <el-col :span="7">
              <el-slider v-model="sliderValue" @change="sliderChange" :marks="marks" range :min="ScoreLimitMin"
                :max="ScoreLimitMax">
              </el-slider>
            </el-col>
          </el-row>
          <el-row class="common-box" :gutter="0">
            <el-col :span="2" class="text">
              <span>意向院校:</span>
            </el-col>
            <el-col :span="8">
              <el-autocomplete style="width: 100%" v-model="searchschool" value-key="SchoolName"
                :fetch-suggestions="querySearchschool" placeholder="请输入学校名称" :trigger-on-focus="false"
                @select="selectSearchschool"></el-autocomplete>
            </el-col>
            <el-col :span="14">
              <el-tag style="margin: 4px" @close="handleClose(item.ID)" v-for="item in SearchSchoolList" :key="item.ID"
                size="medium" closable>{{ item.SchoolName }}</el-tag>
            </el-col>
          </el-row>
          <el-row class="common-box common-boxnone" :gutter="0">
            <el-col :span="2" class="text">
              <span>意向专业:</span>
            </el-col>
            <el-col :span="10">
              <!-- <el-autocomplete
                v-model="searchSpecial"
                value-key="sqName"
                :fetch-suggestions="querySearchSpecialty"
                placeholder="请输入专业名称"
                :trigger-on-focus="false"
                @select="selectSearchSpecialty"
              ></el-autocomplete> -->
              <el-select class="content-search-item intention-area" style="width: 100%" :popper-append-to-body="false"
                popper-class="hide-option" v-model="SearchSpecialtyList" multiple filterable remote placeholder="请输入意向专业"
                :remote-method="searchIntentionArea" :loading="loading" @change="handleChange" @focus="focus">
                <el-option v-for="(item, index) in SpecialtyFullListAll" :key="index" :value="item.ID"
                  :label="item.Name"></el-option>
              </el-select>
              <custom-tree :class="activate ? 'activate' : 'hidden'" :mainData="SpecialtyFullList"
                :changeNode="changeNode" :filterNode="filterNode" @selectNode="selectNodeReceive"></custom-tree>
            </el-col>
            <!-- <el-col :span="18">
              <el-tag
                style="margin: 4px"
                @close="handleClose1(item.ID)"
                v-for="item in SearchSpecialtyList"
                :key="item.ID"
                size="medium"
                closable
                >{{ item.sqName }}</el-tag
              >
            </el-col> -->
          </el-row>
          <el-row class="serach-btn">
            <el-col :offset="11" :span="2">
              <div class="btn-box">
                <!-- <el-button v-if="authorization" type="primary" size="small" @click="handSearchClick">确认筛选</el-button> -->

                <el-button @mouseenter.native="buttonText = limitText"
            @mouseleave.native="buttonText = '确认筛选'"  :type="authorization ? 'primary':(buttonText === '开通会员'?'danger':'primary')" size="small" @click="handleSearch">{{ authorization ? '确认筛选': buttonText }}</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-collapse-transition>
    </div>
    <div @click="handclick">
      <el-row class="transition-btn">
        <el-col :offset="11" :span="2">
          <span>
            {{ show3 ? "收 起" : "展 开" }}
            <i :class="show3 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </span>
        </el-col>
      </el-row>
    </div>
    <bach-List ref="bachlist" :ProvinceId="seachdata.ProvinceId" :ProvinceName="ProvinceName"
      :ExamSubject="seachdata.SelectExamSubjects" :Score="seachdata.Score" :Year="Year" :wishType="wishType"
      @batchChange="batchChange" v-on="$listeners"></bach-List>
    <!-- <batch-dialog @batchChange="batchChange"  v-on="$listeners" ref="batchDialog"></batch-dialog> -->
  </div>
</template>
<script>
import API from '@/api/config'
import {mapGetters} from 'vuex'
// import { BaseCondition, SchoolListBySchoolName } from "@/api/college";
// import { StudentDetail } from "@/api/student";
// import {
//   SpecialtyOrCategorySearch,
//   SpecialtyFullList,
// } from "@/api/newEntrance";
// import BatchDialog from "@/views/user/student/BatchDialog";
import bachList from "./bachlist";
import CustomTree from "@/views/custom-components/CustomTree";
export default {
  components: {
    // BatchDialog,
    bachList,
    CustomTree,
  },
  data() {
    return {
      // 专业筛选
      loading: false,
      activate: false,
      changeNode: [],
      filterNode: "",
      // 意向专业
      searchSpecial: "",
      SearchSpecialtyList: [],
      SpecialtyFullList: [],
      SpecialtyFullListAll: [],

      batchStatus: false,
      show3: false,
      text: "收 起",
      searchList: {},
      // 省份
      SchoolProvinceIdList: [],
      // 院校类型
      SchoolCategoryIdList: [],
      // 热门标签
      SchoolNature: null,
      SchoolTagList: [],
      // 意向学校
      searchschool: "",
      SearchSchoolList: [],

      BatchName: this.$ls.get("searchData").BatchName,
      ProvinceName: this.$ls.get("searchData").ProvinceName,
      Rank: this.$ls.get("searchData").Rank,
      Year: this.$ls.get("searchData").Year,
      wishType: this.$ls.get("searchData").wishType,
      seachdata: {
        ProvinceId: this.$ls.get("searchData").ProvinceId,
        BatchId: this.$ls.get("searchData").BatchId,
        SelectExamSubjects: this.$ls.get("searchData").SelectExamSubjects,
        Score: this.$ls.get("searchData").Score,
        SchoolProvinceIdList: this.SchoolProvinceIdList,
        SchoolCategoryIdList: this.SchoolCategoryIdList,
        SchoolTagList: this.SchoolTagList,
        SchoolNature: this.SchoolNature,
        ScoreEqual: this.$ls.get("searchData").ScoreEqual,
        SchoolIdList: [],
        SpecialtyList: [],
        ScoreLowerLimit: PLATFROM_CONFIG.ScoreLowerLimit || -30,
        ScoreUpperLimit: PLATFROM_CONFIG.ScoreUpperLimit || 15,
      },
      ScoreLimitMax: PLATFROM_CONFIG.ScoreLimitMax || 80,
      ScoreLimitMin: PLATFROM_CONFIG.ScoreLimitMin || -100,
      sliderValue: [
        PLATFROM_CONFIG.ScoreLowerLimit || -30,
        PLATFROM_CONFIG.ScoreUpperLimit || 15,
      ],
      marks: {
        0: {
          style: {
            color: "rgba(var(--themecolor),0.75)",
          },
          label: this.$createElement("strong", "0"),
        },
      },

      buttonText: '确认筛选',
      authorization: true,

      limitText: '开通会员'
    };
  },
  computed: {
    ...mapGetters(["nameConfig"]),
  },
  mounted() {
    this.initialize();
    this.handSearchClick();
    let isThis = this;
    document.onclick = function () {
      isThis.activate = false;
    };


    let temp = this.wishType === 0?'WCFTB':'WDFTB'
    // 权限
    this.$store.dispatch('user/allowMember', [temp]).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    sliderChange(val) {
      if (val[0] > 0) {
        this.$message.warning("分数下限不能大于0");
        this.sliderValue = [PLATFROM_CONFIG.ScoreLowerLimit || -30, val[1]];
      }
      if (val[1] < 0) {
        this.$message.warning("分数上限不能小于0");
        this.sliderValue = [val[0], PLATFROM_CONFIG.ScoreUpperLimit || 15];
      }
    },
    // goBack() {
    //   this.$ls.remove("dataList");
    //   this.$ls.remove("data");
    //   this.$emit("listShowChange");
    // },
    upgradeVIP() {
      this.hasToken
        ? this.$router.push("/VIP/index")
        : this.$router.push('/login')
    },
    changeScore() {
      this.$router.push("/index");
    },
    changeBatch() {
      this.$refs.bachlist.show("batch");

    },
    batchChange() {
      this.BatchName = this.$ls.get("searchData").BatchName;
      this.seachdata.BatchId = this.$ls.get("searchData").BatchId;
      // this.$emit('getWishWay');
      this.handSearchClick();
    },
    initialize() {
      API.School.BaseCondition().then((res) => {
        this.searchList = res.data;
      });
      this.getSpecialtyFullList();

      this.limitText = this.wishType === 0?'开通'+this.nameConfig.WCF:'升级'+this.nameConfig.WDF
    },
    handclick() {
      this.show3 = !this.show3;
    },
    // 确定筛选
    handSearchClick() {
      let SchoolList = [],
        SpecialtyList = [];
      this.SearchSchoolList.forEach((item) => {
        SchoolList.push(item.ID);
      });
      if (this.SearchSpecialtyList.length > 0) {
        this.SpecialtyFullListAll.forEach((item) => {
          const index = this.SearchSpecialtyList.findIndex(
            (ele) => ele === item.ID
          );
          if (index !== -1) {
            SpecialtyList.push({
              Type: item.Type,
              SpecialtyOrCategoryId: item.ID,
            });
          }
        });
      }

      this.seachdata = {
        ProvinceId: this.$ls.get("searchData").ProvinceId,
        BatchId: this.$ls.get("searchData").BatchId,
        SelectExamSubjects: this.$ls.get("searchData").SelectExamSubjects,
        Score: this.$ls.get("searchData").Score,
        ScoreEqual: this.$ls.get("searchData").ScoreEqual,
        SchoolProvinceIdList: this.SchoolProvinceIdList,
        SchoolCategoryIdList: this.SchoolCategoryIdList,
        SchoolTagList: this.SchoolTagList,
        SchoolNature: this.SchoolNature,
        SchoolIdList: SchoolList,
        SpecialtyList: SpecialtyList,
        ScoreLowerLimit: this.sliderValue[0],
        ScoreUpperLimit: this.sliderValue[1],
        
        EducationLevel: this.$ls.get("searchData").EducationLevel
      };
      // this.seachdata.SchoolIdList = SchoolList;
      // this.seachdata.SpecialtyList = SpecialtyList;
      this.$emit("tableList", this.seachdata);
    },
    // 公共方法
    changeactiveId(arr, ID) {
      if (ID) {
        const index = arr.findIndex((elem) => elem === ID);
        if (index === -1) {
          arr.push(ID);
        } else {
          arr.splice(index, 1);
        }
      } else {
        if (arr === this.SchoolTagList) {
          this.SchoolNature = null;
        }
        arr.splice(0);
      }
    },
    getactiveId(arr, ID) {
      let flog = false;
      if (arr.findIndex((elem) => elem === ID) === -1) {
        flog = false;
      } else {
        flog = true;
      }
      return flog;
    },
    changeNature(ID) {
      this.SchoolNature = this.SchoolNature === ID ? null : ID;
    },
    // 意向学校
    selectSearchschool(val) {
      const index = this.SearchSchoolList.findIndex(
        (item) => item.ID === val.ID
      );
      if (index === -1) {
        this.SearchSchoolList.push(val);
      }
      this.searchschool = '';
    },
    querySearchschool(queryString, cb) {
      API.School.SchoolListBySchoolName({
        SchoolName: queryString,
        PageIndex: 1,
        PageSize: 9999,
      }).then((res) => {
        cb(res.data.Data);
      });
    },
    handleClose(ID) {
      const index = this.SearchSchoolList.findIndex((item) => item.ID === ID);
      this.SearchSchoolList.splice(index, 1);
    },
    // 意向专业
    selectSearchSpecialty(val) {
      const index = this.dr.findIndex((item) => item.ID === val.ID);
      if (index === -1) {
        this.SearchSpecialtyList.push(val);
      }
    },
    querySearchSpecialty(queryString, cb) {
      API.Specialty.SpecialtyOrCategorySearch({
        Name: queryString,
      }).then((res) => {
        if (res.data) {
          const Arr = res.data.map((item) => {
            item.sqName = item.Name + "(" + item.EducationLevel + ")";
            return item;
          });
          cb(Arr);
        }
      });
    },
    handleClose1(ID) {
      const index = this.SearchSpecialtyList.findIndex(
        (item) => item.ID === ID
      );
      this.SearchSpecialtyList.splice(index, 1);
    },
    // 专业筛选
    searchIntentionArea(params) {
      this.filterNode = params.trim();
    },
    handleChange(changeParam) {
      this.changeNode = changeParam;
    },
    selectNodeReceive(selectNode) {
      if (this.SearchSpecialtyList.includes(selectNode.ID)) {
        this.SearchSpecialtyList.removeArrayItem(selectNode.ID);
      } else {
        this.SearchSpecialtyList.push(selectNode.ID);
      }
    },
    focus() {
      this.activate = true;
    },
    getSpecialtyFullList() {
      API.Specialty.SpecialtyFullList().then((res) => {
        this.SpecialtyFullList = res.data.map((item) => {
          item.disabled = true;
          return item;
        });
        this.getSpecialtyFullListSub(this.SpecialtyFullList);
        this.SpecialtyFullListAll = [];
        this.getSpecialtyFullListAll(this.SpecialtyFullList);
      });
    },
    getSpecialtyFullListSub(data) {
      data.forEach((item) => {
        if (item.EducationLevel === 1) {
          item.Name = item.Name + "(本)";
        } else if (item.EducationLevel === 0) {
          item.Name = item.Name + "(专)";
        }
        if (item.SubList && item.SubList.length > 0) {
          this.getSpecialtyFullListSub(item.SubList);
        }
      });
    },
    getSpecialtyFullListAll(data) {
      data.forEach((item) => {
        this.SpecialtyFullListAll.push(item);
        if (item.SubList && item.SubList.length > 0) {
          this.getSpecialtyFullListAll(item.SubList);
        }
      });
    },

    handleSearch() {

      if (this.authorization) {
        this.handSearchClick()

      } else {
        let productCode = this.wishType === 0?'WCF':'WDF'
        this.$router.push('/openvip?productCode='+productCode)
      }
    }
  },
};
</script>
<style lang="less" scoped>
.app-container {
  font-size: 14px;
  color: #606266;
}

.seach-box {
  border: 1px solid #e5e5e5;
  border-bottom: none;
  padding: 0 20px;

  ::v-deep .hide-option {
    display: none;
  }

  .hidden {
    display: none;
  }

  .activate {
    display: block;
  }
}

.eldropdown {
  margin-left: 30px;
}

.transition-btn {
  border: 1px solid #e5e5e5;
  line-height: 26px;
  cursor: pointer;
  text-align: center;
}

.common-box {
  border-bottom: 1px dashed #e5e5e5;
  padding: 15px 0;

  .text {
    text-align: center;
    line-height: 36px;
  }

  .list-box {
    display: flex;
    flex-wrap: wrap;

    &>li {
      padding: 0 12px;
      line-height: 24px;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      margin: 5px;
    }

    &>.liactive {
      border-color: rgba(var(--themecolor),0.75);
      color: rgba(var(--themecolor),0.75);
    }
  }
}

.common-boxnone {
  border: none;
}

.serach-btn {
  text-align: center;
  padding: 20px;
  font-size: 16px;
}

.btn-box {
  position: relative;
  height: 32px;

  .btn-hover {
    position: absolute;
    top: 0px;
    left: 9px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    z-index: 999;
  }
}

.wishTypeText {
  font-weight: bold;
  margin: 0 20px;
  font-size: 18px;
  color: rgba(var(--themecolor),0.75);
}

// 组件原生样式调整
::v-deep .el-slider__button-wrapper {
  z-index: 1;
}
.el-dropdown-menu__item:hover{
  color: rgba(var(--themecolor),0.75);
}
</style>
<style lang="less">
.common-box .el-slider__bar{
  background-color: rgba(var(--themecolor),0.75);
}
.common-box .el-slider__button{
  border: 2px solid rgba(var(--themecolor),0.75);
}
.common-box .el-input__inner:checked,.common-box .el-input__inner:focus{
  border: 1px solid rgba(var(--themecolor),0.75);
}
.common-box .el-range-editor.is-active, .common-box .el-range-editor.is-active:hover, .common-box .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.common-box .el-checkbox__input.is-checked .el-checkbox__inner, .common-box .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.common-box .el-checkbox__input .el-checkbox__inner:hover{
  border-color: rgba(var(--themecolor),0.75);
}
.serach-btn .btn-box .el-button--primary{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>