<template>
  <div>
    <el-row class="title-box" :style="{ borderColor: borderColor }">
      <el-col :span="3" v-for="(item, index) in titleList" :key="index">
        <span :class="tabnum === index ? item.className + 'active' : item.className" @click="activeChange(item, index)">{{
          item.GroupName }} {{ item.Number }} 组</span>
      </el-col>
      <el-col :span="2" :offset="12" v-if="$ls.get('userdata') && $ls.get('userdata').EnableExport">
        <span>
          <el-button type="primary" size="small" @click="handExportrue">一键导出</el-button>
        </span>
      </el-col>
    </el-row>
    <div v-if="titleList[tabnum] && titleList[tabnum].tabledata">
      <el-table :data="titleList[tabnum].tabledata" size="small" v-loading="page.loading" tooltip-effect="light"
        @sort-change="sortChange" @header-click="headerClick"
        :header-cell-style="{ background: '#f5f5f5', color: '#606266' }">
        <el-table-column align="center" :label="estimateScoreYear + '预测分'" sortable="custom" width="110">
          <template slot-scope="scope">
            <span class="RankColor">{{ scope.row.ForecastScore }}分</span>
          </template>
        </el-table-column>
        <el-table-column label="院校">
          <template slot-scope="scope">
            <div>
              <span class="RankColor SchoolNamebox" @click="gotoSchoolDetail(scope.row.SchoolId)">{{ scope.row.SchoolName
              }}</span>

              <template v-if="scope.row.TagNames">
                <el-tag style="margin-left: 10px" size="mini" v-for="item in scope.row.TagNames.split(',')" :key="item">{{
                  item }}</el-tag>
              </template>
            </div>
            <div class="color666">
              <div>
                {{ scope.row.CategoryNames }}/{{ scope.row.SchoolNature }}/{{
                  scope.row.ProvinceName
                }}
              </div>
              <span>代码 {{ scope.row.SchoolCode }}</span>
              <div v-if="scope.row.Ranking">
                <el-tag size="mini">{{ scope.row.Ranking + '[' + scope.row.RankingType + ']' }}</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="招生专业">
          <template slot-scope="scope">
            <div>
              <span class="RankColor SchoolNamebox" @click="
                gotoSpecialtyDetail(
                  scope.row.SpecialtyCode,
                  scope.row.SpecialtyName
                )
                ">{{ scope.row.SpecialtyName }}</span>
            </div>
            <div class="color666">代码 {{ scope.row.EnrollmentCode }}</div>
            <div>
              <el-tag size="small" v-if="scope.row.LearnSubjectAssessment">学科评估：{{ scope.row.LearnSubjectAssessment
              }}</el-tag>
              <el-tag size="small" v-if="scope.row.SpecialtyRank">专业排名：{{ scope.row.SpecialtyRank }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="PlanYear + '年招生计划'" width="150">
          <template slot-scope="scope">
            <div>
              <span style="font-weight: bold">{{ scope.row.PlanPersons }}</span>人
            </div>
            <div>
              <span>{{ scope.row.LearningYears }}年</span>
              <span v-if="scope.row.LearningCost">¥{{ scope.row.LearningCost }}/年</span>
            </div>
            <div>{{ scope.row.ExamCondition }}</div>
          </template>
        </el-table-column>


        <el-table-column label="历年录取情况" align="center" width="400">
          <template slot-scope="scope">
            <div class="enroll-item" v-for="(item, index) in enrollShowList(scope.row.EnrollmentList)" :key="index">
              <span>年份：{{ item.Year || '-' }}</span>
              <span v-if="item.subjectType">科目：{{ subjectTypeShow(item) }}</span>
              <span>录取数：{{ item.Persons || '-' }}</span>
              <span>最低分：{{ item.ScoreLow || '-' }}</span>
              <span>最低位次：{{ item.RankLow || '-' }}</span>

            </div>
          </template>
        </el-table-column>


        <el-table-column align="center" label="填报" width="100">
          <template slot-scope="scope">
            <el-button size="small" :type="scope.row.flog ? 'primary' : ''" @click="handClik(scope.row)">{{ scope.row.flog
              ? "已填" : "填报" }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template v-if="titleList[tabnum] && titleList[tabnum].TotalPage">
      <div class="pagination" v-if="titleList[tabnum].TotalPage > 0 &&
        titleList[tabnum].TotalPage !== titleList[tabnum].pageIndex
        ">
        <el-button v-if="authorization" type="primary" :loading="page.loadingMore"
          @click="handleCurrentChange">查看更多</el-button>
        <div v-else>
          <member-limit text_1="升级" :text_2="nameConfig.WDF"></member-limit>
        </div>
      </div>
    </template>
    <exportList ref="exportList" @handeOk="handExport"></exportList>
  </div>
</template>
<script>
import API from '@/api/config'
import {mapGetters} from 'vuex'
// import {
//   NewExamScoreLineForecastGroup,
//   NewExamScoreLineForecast,
//   NewExamTableHeadYear,
//   NewExamScoreLineForecastExport,
// } from "@/api/newEntrance";
import { getStore } from "@/utils/utils";
import exportList from "./exportList";
import MemberLimit from '@/views/choose-wish/local-components/MemberLimit.vue';

export default {
  inject: ["getScrollTop", "setScrollTop"],
  components: {
    exportList,
    MemberLimit
  },
  data() {
    return {
      hasToken: '',
      hasVip: this.$store.state.user.hasVip,
      MemberType: this.$store.getters.roles,
      tabnum: 0,
      activeClass: "",
      borderColor: "",
      searchData: {},
      titleList: [],
      columns: [],
      tabledata: [],
      dataName: "",
      PlanYear: "",
      Year: this.$ls.get("searchData").Year,
      //查看参数
      page: {
        loading: false,
        loadingMore: false,
        ProvinceId: "",
        pageIndex: 1,
        pageSize: 10,
        sizes: [10, 50, 100, 300],
        TotalPage: 0,
      },
      titledata: null,
      PercentSort: 1,
      filterlist: [],

      authorization: true,

      estimateScoreYear: '',

      wishNumber: {},
      allowBeyondNumber: false
    };
  },
  computed: {
    ...mapGetters(['nameConfig'])
  },
  mounted() {
    // this.getTableData();
    let temp = getStore('LoginData')
    this.hasToken = (temp ? JSON.parse(getStore('LoginData')).Token : '')

    // 权限
    this.$store.dispatch('user/allowMember', ['WDFTB']).then(flag => {
      this.authorization = flag
    })

    this.getEstimateScoreYear()
  },
  methods: {
    // 志愿数量提示
    getNumber(value) {
      API.Public.ProvinceWishCount({
        BatchId: value.BatchId,
        ProvinceId: value.ProvinceId,
        EducationLevel: value.EducationLevel
      }).then(res => {
        this.wishNumber = res.data
      })
    },
    getEstimateScoreYear() {
      this.$store.dispatch('tool/getSystemYear').then(year => {
        this.estimateScoreYear = year
      })
      // API.Wish.WishYear().then(res => {
      //   this.estimateScoreYear = res.data.Year
      // })
    },
    handExportrue() {
      this.$refs.exportList.show()
    },
    // 一键导出
    handExport(val) {
      const loading = this.$loading({
        lock: true,
        text: "导出中......",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.searchData.ExportColumnList = val;
      this.$http({
        url:
          window.PLATFROM_CONFIG.baseUrl +
          "/api/Wish/NewExamScoreLineForecastExport",
        method: "post",
        headers: {
          token: getToken('token'),
          "Content-Type": "application/json; charset=utf-8",
        },
        data: this.searchData,
        responseType: "arraybuffer",
      }).then((res) => {
        //这里res.data是返回的blob对象

        var blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        // //从response的headers中获取filename, 后端response.setHeader("Content-Disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        // var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
        // var contentDisposition = decodeURI(res.headers["content-disposition"]);
        // var result = patt.exec(contentDisposition);
        // console.log(blob)
        // var filename = result[1];
        var filename = "导出志愿推荐表";
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob);
        } else {
          var downloadElement = document.createElement("a");
          downloadElement.download = filename;
          downloadElement.href = window.URL.createObjectURL(blob);
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
        }
        loading.close();
        this.$message.success("导出成功");
      }).catch(() => {
        loading.close();
        this.$message.error("导出失败");
      });
    },
    sortChange(column, prop, order) {
      if (column.order && column.order === "descending") {
        this.PercentSort = 1;
      } else if (column.order && column.order === "ascending") {
        this.PercentSort = 0;
      } else {
        this.PercentSort = 1;
      }
      this.getList();
    },
    getTableData(val) {
      if (val) {
        this.searchData = val;

        this.getNumber(val)
      }
      API.Wish.NewExamScoreLineForecastGroup(this.searchData).then((res) => {
        if (res.data) {
          this.titleList = res.data.map((item, index) => {
            if (index === 0) {
              item.className = "frist";
              item.color = "#66b1ff";
            } else if (index === 1) {
              item.className = "two";
              item.color = "#f8cc3d";
            } else {
              item.className = "last";
              item.color = "#51d629";
            }
            item.tabledata = [];
            item.TotalPage = 0;
            item.pageIndex = 1;
            return item;
          });
          this.getNewExamTableHeadYear();
          this.getList();
        }
      });
    },
    loginDialogActivate() {
      this.$router.push('/login')
    },
    getNewExamTableHeadYear() {
      API.Wish.NewExamTableHeadYear({
        ProvinceId: this.$ls.get("searchData").ProvinceId,
        SelectExamSubjects: this.$ls.get("searchData").SelectExamSubjects,
      }).then((res) => {
        if (res.code === 0) {
          this.PlanYear = res.data.PlanYear;
          this.filterlist = res.data.Years;
          this.dataName = this.filterlist[0];
        }
      });
    },
    getList() {
      this.page.loading = true;
      this.page.pageIndex = 1;
      const data = {
        PercentSort: this.PercentSort,
        PercentBegin: this.titleList[this.tabnum].ScoreBegin,
        PercentEnd: this.titleList[this.tabnum].ScoreEnd,
        pageIndex: this.page.pageIndex,
        pageSize: this.page.pageSize,
      };
      const searchData = Object.assign(this.searchData, data);
      API.Wish.NewExamScoreLineForecast(searchData).then((res) => {
        if (res.code === 0) {
          this.titleList[this.tabnum].tabledata = res.data.Data.map((item) => {
            item.flog = false;
            item.EnrollmentPercent = item.ForecastScore;
            item.SpecialtyEnrollmentCode = item.EnrollmentCode;
            return item;
          });

          // this.titleList[this.tabnum].tabledata = this.titleList[this.tabnum].tabledata;
          this.titleList[this.tabnum].TotalPage = res.data.TotalPage;
          this.getLsdata();
        }
        this.page.loading = false;
      });
    },
    getListMore() {
      const height = this.getScrollTop();
      this.page.loadingMore = true;
      const data = {
        PercentSort: this.PercentSort,
        PercentBegin: this.titleList[this.tabnum].ScoreBegin,
        PercentEnd: this.titleList[this.tabnum].ScoreEnd,
        pageIndex: this.titleList[this.tabnum].pageIndex,
        pageSize: this.page.pageSize,
      };
      const searchData = Object.assign(this.searchData, data);
      API.Wish.NewExamScoreLineForecast(searchData).then((res) => {
        if (res.code === 0) {
          const tabledata = res.data.Data.map((item) => {
            item.flog = false;
            item.EnrollmentPercent = item.ForecastScore;
            item.SpecialtyEnrollmentCode = item.EnrollmentCode;
            return item;
          });
          this.titleList[this.tabnum].tabledata =
            this.titleList[this.tabnum].tabledata.concat(tabledata);
          this.titleList[this.tabnum].TotalPage = res.data.TotalPage;
          this.getLsdata();
        }
        this.page.loadingMore = false;
        setTimeout(() => {
          this.setScrollTop(height);
        });
      });
    },
    activeChange(item, index) {
      // this.tabnum = index;
      // this.activeClass = item.className + "active";
      // this.borderColor = item.color;
      // if (this.titleList[this.tabnum].tabledata.length === 0) {
      //   this.getList();
      // }


      if (index !== 0) {

        this.$store.dispatch('user/allowMember', ['WDFTB']).then(flag => {
          if (flag) {

            this.tabnum = index;
            this.activeClass = item.className + "active";
            this.borderColor = item.color;
            if (this.titleList[this.tabnum].tabledata.length === 0) {
              this.getList();
            }
          } else {

            this.$confirm("此功能需要升级"+this.nameConfig.WDF, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.$router.push('/openvip?productCode=WDF')
            }).catch(() => { });
          }
        })

      } else {
        this.tabnum = index;
        this.activeClass = item.className + "active";
        this.borderColor = item.color;
        if (this.titleList[this.tabnum].tabledata.length === 0) {
          this.getList();
        }
      }


    },
    // 数据筛选
    headerClick(column, event) {
      // event.target.childNodes[1].click()
      // console.log('ooo',event)
    },
    // 加载更多
    handleCurrentChange() {
      this.titleList[this.tabnum].pageIndex += 1;
      this.getListMore();
    },

    // 数据筛选
    changeBatch(value) {
      this.dataName = value;
    },
    handClik(row) {
      if (!this.hasToken) {
        this.loginDialogActivate();
      } else {
        let Arr = this.$ls.get("data");
        if (Arr && Arr.length > 0) {
          const index = Arr.findIndex(
            (ele) =>
              // ele.SchoolId === row.SchoolId &&
              ele.ID === row.ID &&
              ele.SpecialtyEnrollmentCode === row.SpecialtyEnrollmentCode
          );
          if (index !== -1) {
            Arr.splice(index, 1);
            
          } else {

            // add

            // 是否可以超过省份标准数量限制
            if(this.allowBeyondNumber){
              Arr.push(row);
              this.fillWish(Arr)
            }else{
              if(Arr.length >= this.wishNumber.WishCount){
                this.$confirmTips('您填报的志愿数量已超过'+this.wishNumber.WishCount+'个，是否继续填报？', '注意！', 'warning').then(_=> {
                  this.allowBeyondNumber = true
                  Arr.push(row);
                  this.fillWish(Arr)
                }).catch(_=>{
                  this.allowBeyondNumber = false

                  this.fillWish(Arr)
                })
              }else{
                Arr.push(row);
                this.fillWish(Arr)
              }
            }

            // Arr.push(row);
          }
        } else {
          // first add

          Arr = [];
          Arr.push(row);

          this.fillWish(Arr)
        }
        // this.$ls.set("data", Arr);
        // this.getLsdata();
        // this.$emit("tableList");
        this.fillWish(Arr)
      }
    },
    // 缓存数据
    fillWish(Arr) {
      this.$ls.set("data", Arr);
      this.getLsdata();
      this.$emit("tableList");
    },
    // 按钮状态
    getLsdata() {
      let Arr = this.$ls.get("data");
      this.titleList[this.tabnum].tabledata.forEach((item) => {
        if (Arr && Arr.length > 0) {
          const index = Arr.findIndex(
            (ele) =>
              // ele.SchoolId === item.SchoolId &&
              ele.SchoolCode === item.SchoolCode &&
              ele.SpecialtyEnrollmentCode === item.SpecialtyEnrollmentCode
          );
          if (index !== -1) {
            item.flog = true;
          } else {
            item.flog = false;
          }
        } else {
          item.flog = false;
        }
      });
    },
    // 院校详情
    gotoSchoolDetail(ID) {
      let routerUrl = this.$router.resolve({
        path: "/university-detail",
        query: { id: ID },
      });
      window.open(routerUrl.href, "_blank");
    },
    // 专业详情
    gotoSpecialtyDetail(ID, Name) {
      let routerUrl = {};
      if (ID && ID.length === 6) {
        routerUrl = this.$router.resolve({
          path: "/specialty-detail",
          query: { SpecialtyCode: ID },
        });
      } else {
        routerUrl = this.$router.resolve({
          path: "/specialty-list",
          query: { CategoryCode: ID, CategoryName: Name },
        });
      }

      window.open(routerUrl.href, "_blank");
    },
    getEnrollment(Arr) {
      let data = null;
      const index = Arr.findIndex(
        (ele) => ele.Year === this.dataName && !ele.SubjectType
      );
      if (index != -1) {
        data = Arr[index];
      }
      return data;
    },
    getEnrollment1(Arr) {
      let data = null;
      const index = Arr.findIndex(
        (ele) => ele.Year === this.dataName && ele.SubjectType === 1
      );
      if (index != -1) {
        data = Arr[index];
      }
      return data;
    },
    getEnrollment2(Arr) {
      let data = null;
      const index = Arr.findIndex(
        (ele) => ele.Year === this.dataName && ele.SubjectType === 2
      );
      if (index != -1) {
        data = Arr[index];
      }
      return data;
    },
    // 历年录取情况列表中，仅展示最近三年的数据
    enrollShowList(list) {
      return list.slice(0, 3)
    },
    // 需要展示科目时，判断文理
    subjectTypeShow(item) {
      return item.subjectType && (item.subjectType === 1 ? '文' : '理')
      // return '文'
    }
  },
};
</script>
<style lang="less" scoped>
.title-box {
  border-bottom: 2px solid rgba(var(--themecolor),0.75);
  text-align: center;
  font-size: 20px;
  padding: 30px 0 10px;

  span {
    cursor: pointer;
  }

  .frist:hover {
    color: rgba(var(--themecolor),0.75);
  }

  .two:hover {
    color: rgba(var(--themecolor),0.75);
  }

  .last:hover {
    color: rgba(var(--themecolor),0.75);
  }
}

.fristactive {
  color: rgba(var(--themecolor),0.75);
}

.twoactive {
  color: rgba(var(--themecolor),0.75);
}

.lastactive {
  color: rgba(var(--themecolor),0.75);
}

.RankColor {
  font-size: 18px;
}

.color666 {
  color: #666;
}

.SchoolNamebox {
  cursor: pointer;
}

.pagination {
  margin-top: 10px;
  text-align: center;
}

.commomdiv {
  height: 20px;
}

.enroll-item {
  padding: 5px 10px;
  margin-bottom: 3px;
  background-color: #fefefe;
  box-shadow: 0 0 3px 0 var(--boxShadow);
  border-radius: 2px;

  display: flex;
  justify-content: space-between;
}
</style>
<style lang="less">
.el-tag{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.1);
  border-color: rgba(var(--themecolor),0.1);
}
.el-table .descending .sort-caret.descending{
  border-top-color: rgba(var(--themecolor),0.75);
}
.el-table .ascending .sort-caret.ascending{
  border-bottom-color: rgba(var(--themecolor),0.75);
}
.el-button:focus, .el-button:hover{
  color: rgba(var(--themecolor),0.75);
}
.el-button:focus, .el-button:hover{
  border-color: rgba(var(--themecolor),0.15);
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>