<template>
  <div class="main-box">
    <div class="sub-box">
      <div class="btn-box" @click="handBtn">
        {{ flog ? "收起" : "展开" }}志愿表 {{ subnum }}
      </div>
      <div class="list-box" :class="flog ? 'flogleft' : 'flogright'" :style="{ height: boxHeight }">
        <div class="sublist" v-if="listData.length > 0">
          <el-input placeholder="请输入内容" v-model="inputvalue" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
          <!-- 志愿列表 -->
          <el-row :gutter="10" class="list-title">
            <el-col :span="3">志愿</el-col>
            <el-col :span="11">院校</el-col>
            <el-col :span="3">
              <!-- <el-button style="padding-top: 0;" @click="moveSort" type="text">{{ moveText }}</el-button> -->
              <el-button style="padding-top: 0;" type="text"></el-button>
            </el-col>
            <el-col :span="3">{{
              { 0: "录取概率", 1: "预测分" }[$ls.get("searchData").wishType]
            }}</el-col>
            <el-col :span="4">操作(排序，删除)</el-col>
          </el-row>
          <div id="drag_box" class="list-body" :style="{ height: listHeight }">
            <el-row :gutter="10" :class="['list-mes', { disabledDrag: disabledStatus }]" :data-sort="index"
              v-for="(item, index) in listData" :key="item.SpecialtyName + item.SchoolName">

              <div v-show="insertBlock" class="insert" @click="insertWish(index)">
                <span class="insert-button">插入</span>
              </div>
              <div class="top-box">
                <el-checkbox :disabled="disabledStatus" v-model="item.checkFlag">选择</el-checkbox>
                <div :class="['drag-text', { 'disabled-style': disabledStatus }]">此区域可拖拽排序</div>
                <div v-if="false" class="floor-sort">
                  <el-button style="padding: 0;" @click="openFloorSort(index)" :disabled="disabledStatus" type="text" size="mini">快速排序</el-button>
                </div>
              </div>
              <el-col :span="2">
                <span class="colormain">{{ index + 1 }}</span>
              </el-col>
              <el-col :span="7">
                <div class="audiot_style">
                  {{ item.SchoolName }}
                </div>
                <div v-if="item.Ranking">
                  <el-tag size="mini">{{ item.Ranking + '[' + item.RankingType + ']' }}</el-tag>
                </div>
              </el-col>

              <el-col :span="8">
                <div class="audiot_style1">
                  {{ item.SpecialtyName }}
                </div>

                <div>
                  <el-tag size="small" v-if="item.LearnSubjectAssessment">学科评估：{{ item.LearnSubjectAssessment
                  }}</el-tag>
                  <el-tag size="small" v-if="item.SpecialtyRank">专业排名：{{ item.SpecialtyRank }}</el-tag>
                </div>
              </el-col>
              <el-col :span="3">
                <div class="EnrollmentBox">
                  {{
                    item.EnrollmentPercent +
                    { 0: "%", 1: "" }[$ls.get("searchData").wishType]
                  }}
                </div>
              </el-col>
              <el-col :span="4" class="sort-box">
                <!-- <span>
                  <i
                    v-if="index !== 0"
                    class="el-icon-caret-top"
                    @click="caretTop(index)"
                  ></i>
                  <i
                    v-if="index !== listData.length - 1"
                    class="el-icon-caret-bottom"
                    @click="caretBottom(index)"
                  ></i>
                </span> -->
                <el-button style="padding-top: 0;" @click="deleteData(index)" :disabled="disabledStatus"
                  icon="el-icon-delete" type="text"></el-button>
                <!-- <i class="el-icon-delete" @click="deleteData(index)"></i> -->
              </el-col>
            </el-row>
            <div v-show="insertBlock" class="insert">
              <span class="insert-button" @click="insertWish(listData.length)">插入</span>
            </div>
          </div>
          <div class="list-btn-box">
            <el-button class="list-btn" type="primary" @click="saveList">保存志愿表</el-button>
            <!-- <div class="hide-node mxh80">
              <el-alert title="注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站；
                    申明：智能推荐是国内唯一一家使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。"
                type="warning" :closable="false" />
            </div> -->
          </div>
        </div>
        <div v-else class="defultbox">暂无志愿...</div>
      </div>
    </div>
    <floor-sort v-model="showFloor" :open-index="openIndex" :total-length="totalLength"
      v-on="{ dragChange: dragChange, sortChange: sortChange}"></floor-sort>
  </div>
</template>
<script>
import API from '@/api/config'
import sortable from 'sortablejs'
import { customClone } from '@/utils/index';
import { getStore } from "@/utils/utils";
// import {
//   WishSpecialtyGroupSave,
//   NewExamTableHeadYear,
// } from "@/api/newEntrance";
import { setTimeout } from "timers";
import FloorSort from '../custom-components/FloorSort.vue';
export default {
  components: {
    FloorSort
  },
  data() {
    return {
      boxHeight: "0px",
      listHeight: "0px",
      subnum: 0,
      flog: false,
      listData: [],
      inputvalue: "",
      PlanYear: "",
      // 插入排序
      insertBlock: false,
      moveText: '移动',
      disabledStatus: false,
      // 楼层排序
      showFloor: false,
      openIndex: 0, // 当前位置
      totalLength: 0, // 列表总长度
    };
  },
  mounted() {
    this.show();
    this.getNewExamTableHeadYear();

    this.$nextTick(() => {
      this.dragBox()
    })
  },
  methods: {
    // 拖拽结束后的回调
    sortChange(params, callback) {
      
      let temp = this.listData.splice(params.oldIndex, 1)[0]

      this.listData.splice(params.newIndex, 0, temp)

      this.$ls.set("data", this.listData)

      // 显示区域定位到拖拽后元素新的位置

      let ele_box = document.getElementById('drag_box')

      // 渲染完后再定位才能准确
      this.$nextTick(() => {
        ele_box.childNodes[params.newIndex].scrollIntoView()

        this.showFloor = false
        callback()
      })
    },
    // 楼层组件回调
    dragChange(index) {
      // index 为索引参数
      let ele_box = document.getElementById('drag_box')

      ele_box.childNodes[Number(index)].scrollIntoView()
    },

    // 激活楼层排序
    openFloorSort(index) {
      this.showFloor = true
      this.openIndex = index
      this.totalLength = this.listData.length
    },

    // 插入志愿
    insertWish(index) {
      // 将被选中的项筛选出来
      let selected = this.listData.filter(item => {
        return item.checkFlag
      })

      // 深拷贝被选出的新数组，防止下一步，改变总数组中该项状态时，新数组也会改变
      selected = selected.map(item => {
        return customClone(item)
      })

      // 将原本数组中被选中的项的状态，改为true，方便后续清洗数组时识别这些项
      this.listData.forEach(item => {
        if (item.checkFlag) {
          item.sortStatus = true
        }
      })

      // 将新数组插入，并合并到总数组的指定位置
      // 先从指定位置将数组切成两段小数组，然后再将三个数组合并起来（slice方法不会改变原数组）
      let temp1 = this.listData.slice(0, index)
      let temp2 = this.listData.slice(index)

      this.listData = temp1.concat(selected).concat(temp2)

      // 清洗状态未true的项，（将没有标记状态的项重新放到一个新数组中，再用新数组覆盖原数组，即完成清洗）
      this.listData = this.listData.filter(item => {
        item.checkFlag = false // 将所有项的勾选状态取消
        return !item.sortStatus
      })

      // 恢复列表的所有可操作状态
      this.moveText = '移动'
      this.insertBlock = false
      this.disabledStatus = false

    },
    // 点击移动
    moveSort() {
      if (this.moveText === '移动') {

        // 判断是否有勾选项
        let tempIndex = this.listData.findIndex(item => item.checkFlag)

        if (tempIndex === -1) {
          this.$message({
            message: '未勾选志愿！',
            type: 'warning',
            showClose: true
          })
        } else {
          this.moveText = '取消'
          this.disabledStatus = true
          this.insertBlock = true
        }

      } else {
        this.moveText = '移动'
        this.disabledStatus = false
        this.insertBlock = false
      }


    },
    // 拖拽排序方法
    dragBox() {
      let id_ele = document.getElementById('drag_box') // 获取拖拽盒子

      if (id_ele) {
        let config = {
          animation: 300,
          ghostClass: 'preview-class',
          filter: '.disabledDrag',
          // forceFallback: false,
          onStart: () => { },

          onEnd: ({ newIndex, oldIndex }) => {

            let temp = this.listData.splice(oldIndex, 1)[0]
            this.listData.splice(newIndex, 0, temp)

            this.$ls.set("data", this.listData)
          },


          // 移动中
          onMove: (evt, original) => {
            // console.log(evt, 'evt...')
            // console.log(original, 'original...')

            // console.log(evt.related.dataset, 'dataset')

          },

          onSort: evt => {
          }
        }

        sortable.create(id_ele, config)
      }
    },

    show() {
      this.boxHeight = document.documentElement.clientHeight - 0 + "px";
      this.listHeight = document.documentElement.clientHeight - 220 + "px";
      const Arr = this.$ls.get("data");

      if (Arr && Arr.length > 0) {
        this.subnum = Arr.length;
        this.listData = Arr.map(item => {
          this.$set(item, 'checkFlag', false)
          this.$set(item, 'sortStatus', false)

          return item
        })

        setTimeout(() => {
          this.search();
        }, 10);
      } else {
        this.listData = [];
        this.subnum = 0;
      }

      this.dragBox()
    },
    handBtn() {
      this.flog = !this.flog;
      if (this.flog) {
        this.moveText = '移动'
        this.disabledStatus = false
        this.insertBlock = false
      }
    },
    loginDialogActivate(activate) {
      this.$router.push('/login')
    },
    saveList() {

      let temp = getStore('LoginData')

      if(!temp) {
        this.$message.info('请先登录！')
        return
      }

      this.listData.forEach((ele, index) => {
        
        ele.WishScoreList = []
        if (ele.EnrollmentList && ele.EnrollmentList.length > 0) {
          ele.WishScoreList = this.getScoreLow(ele.EnrollmentList);
        }
        ele.WishCode = String(index + 1);


      });
      const SelectExamSubject = this.$ls.get("searchData").SelectExamSubjects;
      const SelectExamSubjects = SelectExamSubject.map((item) => {
        item.SelectExamSubjects = item.SelectExamSubject;
        return item;
      });
      const WishRecordList = this.listData.map((item, index) => {
        item.SpecialtyEnrollmentCode = item.EnrollmentCode || item.SpecialtyEnrollmentCode;
        

        item.SchoolId = item.SchoolId || null
        item.SchoolName = item.SchoolName || null
        item.SchoolCode = item.SchoolCode || null


        item.ExamCondition = item.ExamCondition || null // ******


        item.PlanPersons = item.PlanPersons || null
        item.LearningYears = item.LearningYears || null
        item.LearningCost = item.LearningCost || null
        
        item.ScoreLowYear = item.ScoreLowYear || null
        item.ScoreLow = item.ScoreLow || null
        item.RankLow = item.RankLow || null
        item.SpecialtyName = item.SpecialtyName || null
        item.SpecialtyCode = item.SpecialtyCode || null
        item.EnrollmentSpecialty = item.EnrollmentSpecialty || null

        item.EnrollmentPercent = ((this.$ls.get("searchData").wishType === 0)?item.EnrollPercent : item.ForecastScore)
        item.LearnSubjectAssessment = item.LearnSubjectAssessment || null

        item.SpecialtyRank = item.SpecialtyRank || null

        item.Ranking = item.Ranking || null
        item.RankingScore = item.RankingScore || null
        item.RankingSort = item.RankingSort || null
        item.RankingType = item.RankingType || null
        
        return item;
      });

      const data = {
        ID: this.$ls.get("searchData").ID,
        OrgId: JSON.parse(getStore('LoginData')).OrgId,
        UserId: JSON.parse(getStore('LoginData')).UserId,
        ExamProvinceId: this.$ls.get("searchData").ProvinceId,
        ExamProvinceName: this.$ls.get("searchData").ProvinceName,
        WishWay: this.$ls.get("searchData").WishWay,
        WishType: this.$ls.get("searchData").wishType,
        Score: this.$ls.get("searchData").Score,
        ScoreEqual: this.$ls.get("searchData").ScoreEqual,
        RankLow: this.$ls.get("searchData").Rank || null,
        ScoreDiff: this.$ls.get("searchData").ScoreDiff,
        BatchId: this.$ls.get("searchData").BatchId,
        BatchName: this.$ls.get("searchData").BatchName,
        SelectSubjectList: SelectExamSubjects,
        Year: this.$ls.get("searchData").Year,
        PlanYear: this.PlanYear,
        WishRecordList: WishRecordList,
      };
      API.User.SaveWishSpecialtySchool(data).then((res) => {
        if (res.code === 0) {
          this.$ls.remove("dataList");
          this.$ls.remove("data");
          let routerUrl = this.$router.resolve({
            path: "/wish-detail",
            query: {
              WishId: res.data,
            },
          });
          // this.$emit("listShowChange");
         
          window.open(routerUrl.href);
        }
      });
    },
    getScoreLow(arr) {

      return arr.map(item => {
        return {
          Year: item.Year,
          ScoreLow: item.ScoreLow,
          RankLow: item.RankLow
        }
      })
    },
    getNewExamTableHeadYear() {
      API.Wish.NewExamTableHeadYear({
        ProvinceId: this.$ls.get("searchData").ProvinceId,
        SelectExamSubjects: this.$ls.get("searchData").SelectExamSubjects,
      }).then((res) => {
        if (res.code === 0) {
          this.PlanYear = res.data.PlanYear;
        }
      });
    },
    caretTop(index) {
      const data = this.listData[index - 1];
      this.listData[index - 1] = this.listData[index];
      this.listData[index] = data;
      this.resetData();
    },
    caretBottom(index) {
      const data = this.listData[index + 1];
      this.listData[index + 1] = this.listData[index];
      this.listData[index] = data;
      this.resetData();
    },
    deleteData(index) {
      // const index = this.listData.findIndex(ele=>ele.sqcode === item.sqcode);
      this.listData.splice(index, 1);
      this.resetData();
      this.$emit("tableList");
    },
    resetData() {
      this.$ls.set("data", this.listData);
      this.show();
      // this.search();
    },
    search() {
      //translateText为从vuex穿过来的对话内容；
      // inputvalue为搜索框中的value
      // console.log(this.listData);
      // console.log(this.inputvalue);
      // 获取所有对话内容的dom节点
      var audiot_style = document.getElementsByClassName("audiot_style");
      var audiot_style1 = document.getElementsByClassName("audiot_style1");
      var translateText = this.listData;
      var inputvalue = this.inputvalue;

      // 遍历所有对话文本内容
      for (let i = 0; i < translateText.length; i++) {
        audiot_style[i].innerHTML = translateText[i].SchoolName;
        audiot_style1[i].innerHTML = translateText[i].SpecialtyName;
        // 当对话内容中有包含搜索框中的字符串时
        if (translateText[i].SchoolName.indexOf(inputvalue) >= 0) {
          // 先将包含关键字的对话内容拆分为数组
          var values = translateText[i].SchoolName.split(inputvalue);
          // 然后再以一段设置了css样式的标签为分隔符,将数组拼接为字符串
          // 再赋值给对应的dom,让其节点的innerhtml为这个字符串
          audiot_style[i].innerHTML = values.join(
            '<span style="color:red;">' + inputvalue + "</span>"
          );
          // console.log(audiot_style[i].scrollTop);
        }
        if (translateText[i].SpecialtyName.indexOf(inputvalue) >= 0) {
          // 先将包含关键字的对话内容拆分为数组
          var values = translateText[i].SpecialtyName.split(inputvalue);
          // 然后再以一段设置了css样式的标签为分隔符,将数组拼接为字符串
          // 再赋值给对应的dom,让其节点的innerhtml为这个字符串
          audiot_style1[i].innerHTML = values.join(
            '<span style="color:red;">' + inputvalue + "</span>"
          );
        }
      }
    },
  },
};

</script>
<style lang="less" scoped>
.main-box {
  position: fixed;
  z-index: 999;
  bottom: 0px;
  right: 0px;
}

.list-box {
  background: #fff;
  border: 1px solid #e5e5e5;
  width: 0px;
  transition: width 0.5s;
}

.flogright {
  width: 0px;
  border: none;
}

.flogleft {
  width: 700px;
  border: 1px solid #e5e5e5;
}

.sub-box {
  display: flex;
}

.btn-box {
  box-sizing: border-box;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  font-size: 18px;
  background: rgba(var(--themecolor),1);
  color: #fff;
  text-align: center;
  margin-top: 100px;
}

.sublist {
  padding: 20px 30px;
  box-sizing: border-box;

  .list-title {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
  }

  .list-body {
    position: relative;

    width: 100%;
    overflow-y: scroll;
    overflow: auto;
    padding: 10px;

    .list-mes {
      margin-bottom: 10px;

      .top-box {
        padding: 8px 10px;
        margin-bottom: 5px;
        user-select: none;
        background-color: rgba(204, 204, 204, 0.35);

        display: flex;

        .drag-text {
          flex-grow: 1;
          padding-left: 30px;
          cursor: pointer;
          font-style: italic;
        }

        .disabled-style {
          color: #ccc;
        }
      }

      .sort-box {
        font-size: 18px;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          width: 26px;
        }

        i {
          cursor: pointer;
        }

        i:hover {
          color: rgba(var(--themecolor),0.75);
        }
      }
    }

    .insert {
      padding: 5px 0;
      margin-bottom: 5px;
      text-align: center;

      box-shadow: 0 0 10px #ff8787 inset;
      cursor: pointer;
      .insert-button {
        color: rgba(var(--themecolor),0.75);
      }
    }
  }

  /* 设置滚动条的样式 */
  .list-body::-webkit-scrollbar {
    width: 4px;
  }

  /* 滚动槽 */
  .list-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  /* 滚动条滑块 */
  .list-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  .list-btn-box {
    width: calc(100% - 100px);
    position: absolute;

    // bottom: 20px;
    .list-btn {
      width: 100%;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

.colormain {
  color: rgba(var(--themecolor),1);
}

.defultbox {
  text-align: center;
  margin: 100px auto;
  font-size: 20px;
  color: rgba(var(--themecolor),1);
}

.EnrollmentBox {
  text-align: center;
}

.preview-class {
  background-color: rgba(205, 202, 202, 0.3);
}
</style>
<style lang="less">
.el-button--text{
  color: rgba(var(--themecolor),0.75);
}
</style>