<template>
  <div class="main-box">
    <div class="sub-box">

      <div class="list-box" :class="flog ? 'flogleft' : 'flogright'"><!-- height -->
        <div class="btn-box" @click="handBtn">
          {{ flog ? "收起" : "展开" }}志愿表 {{ subnum }}
        </div>
        <div class="sublist" v-if="listData.length > 0">
          <el-input placeholder="请输入内容" v-model="inputvalue" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
          <!-- 志愿列表 -->
          <el-row :gutter="10" class="list-title">
            <el-col :span="6">院校</el-col>
            <el-col :span="6">专业</el-col>
            <el-col :span="12">
              <div class="center-box">
                <!-- <span class="name">院校/专业</span> -->
                <!-- <el-button class="move-button" @click="moveSort" type="text">{{ moveText }}</el-button> -->
                <el-button class="move-button" type="text"></el-button>
              </div>
            </el-col>
            <!-- <el-col :span="3">{{
              { 0: "录取概率", 1: "预测分" }[$ls.get("searchData").wishType]
            }}</el-col>
            <el-col :span="5">操作(排序，删除)</el-col> -->
          </el-row>
          <div id="drag_box" class="list-body"><!-- height -->
            <!-- SchoolName、ExamCondition有可能同时相同，所以需要加上index -->
            <div :class="['list-mes', { disabledDrag: disabledStatus }]" v-for="(item, index) in listData"
              :key="item.SchoolName + item.ExamCondition + index">
              <div v-show="insertBlock" class="insert" @click="insertWish(index)">
                <span class="insert-button">插入</span>
              </div>

              <!-- 每项志愿布局 -->
              <div class="wish-box">
                <div class="topbar operate-sort">
                  <el-tag type="danger" effect="dark">{{ index + 1 }}志愿</el-tag>
                  <el-checkbox class="check" v-model="item.checkFlag" :disabled="disabledStatus">选择</el-checkbox>

                  <div :class="['top-drag', { disabeldStyle: disabledStatus }]">此区域可拖拽排序</div>
                  <div>
                    <el-button class="delete-button" @click="deleteData(index)" :disabled="disabledStatus" type="danger" size="mini">删除</el-button>
                  </div>
                  <div v-if="false" class="floor-sort">
                    <el-button @click="openFloorSort(index)" :disabled="disabledStatus" type="text" size="mini">快速排序</el-button>
                  </div>
                </div>
                <div class="container">
                  <div class="left">
                    <!-- 学校名字 -->
                    <div class="audiot_style">{{ item.SchoolName }}</div>
                    <div v-if="item.Ranking">
                      <el-tag size="mini">{{ item.Ranking + "[" + item.RankingType + "]" }}</el-tag>
                    </div>
                    <!-- 代码 -->
                    <div class="school-code">{{ item.SchoolCode }}</div>
                    <!-- 服从调剂 -->
                    <div>
                      服从调剂：<el-switch v-model="item.IsTransfer" :width="30"></el-switch>
                    </div>
                    <!-- 计划人数 -->
                    <div class="plan">
                      {{ PlanYear }}计划：{{ item.PlanPersons }}
                    </div>
                    <!-- 最低分和最低位 -->
                    <div>{{ item.ScoreLowYear }}最低分：{{ item.ScoreLow }}</div>
                    <div>{{ item.ScoreLowYear }}最低位：{{ item.RankLow }}</div>
                    <!-- 预测分或概率 -->
                    <div class="level-value">{{ countLevelValue(item) }}</div>
                  </div>

                  <!-- 专业 -->
                  <div class="right">
                    <div class="specialty-header">
                      <div class="title">专业代码</div>
                      <div class="title">{{ $ls.get('searchData').wishType === 0 ? '录取概率' : '预测分' }}</div>
                      <div class="title">开设专业</div>
                    </div>
                    <div class="specialty-box" v-for="(ite, ind) in item.WishSpecialtyList" :key="String(ind) + ite.ID">

                      <!-- 代码 -->
                      <div class="specialty-code">{{ ite.SpecialtyCode }}</div>
                      <!-- 概率或预测分 -->
                      <!-- 加空字符串将数字变成字符串 -->
                      <div>{{ ite.EnrollmentPercent + ($ls.get('searchData').wishType === 0 ? '%' : '') }}</div>
                      <!-- 名字 -->
                      <div class="specialty-name">
                        <div class="audiot_style1">
                          {{ ite.SpecialtyName }}
                        </div>
                        <el-tag v-if="ite.LearnSubjectAssessment" size="mini">学科评估{{ ite.LearnSubjectAssessment
                        }}</el-tag>
                        <el-tag v-if="ite.SpecialtyRank" size="mini">专业排名{{ ite.SpecialtyRank }}</el-tag>
                      </div>

                      <!-- 概率或分数 -->
                      <!-- <div v-if="ite.EnrollmentPercent">
                        {{
                          ite.EnrollmentPercent +
                          { 0: "%", 1: "" }[$ls.get("searchData").wishType]
                        }}
                      </div> -->
                      <!-- 操作 -->
                      <div class="specialty-icon">
                        <span v-show="!disabledStatus" class="arrow-icon">
                          <i v-if="ind !== 0" class="el-icon-arrow-up" @click="caretTopSpecialty(index, ind)"></i>
                          <i v-if="ind !== item.WishSpecialtyList.length - 1" class="el-icon-arrow-down"
                            @click="caretBottomSpecialty(index, ind)"></i>
                        </span>
                        <el-button @click="deleteDataSpecialty(index, ind)" :disabled="disabledStatus" type="text"
                          icon="el-icon-delete"></el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- 操作条 -->
              <!-- <div class="operate-sort">
                <el-checkbox class="check" v-model="item.checkFlag" :disabled="disabledStatus">选择</el-checkbox>
                <div :class="['top-drag', { disabeldStyle: disabledStatus }]">此区域可拖拽排序</div>
                <div class="floor-sort">
                  <el-button style="padding: 0;" @click="openFloorSort(index)" type="text" size="mini">快速排序</el-button>
                </div>
              </div> -->


              <el-row :gutter="10" class="list-mes">
                <!-- <el-col class="colormain" :span="2">
                  <span>{{ index + 1 }}</span>
                </el-col>
                <el-col :span="3" class="colormain">
                  <el-switch v-model="item.IsTransfer" :width="30"></el-switch>
                </el-col> -->

                <el-col :span="19" class>

                  <!-- <div class="school-box">
                    <div class="school-name">
                      <div class="name-box">
                        <div class="audiot_style">{{ item.SchoolName }}</div>
                      </div>
                      <div v-if="item.Ranking">
                        <el-tag size="mini">{{ item.Ranking + "[" + item.RankingType + "]" }}</el-tag>
                      </div>
                    </div>
                    <div class="EnrollmentBox" v-if="item.EnrollmentPercent">
                      {{
                        item.EnrollmentPercent +
                        { 0: "%", 1: "" }[$ls.get("searchData").wishType]
                      }}
                    </div>
                    <div class="delete-icon">
                      <el-button style="padding-top: 0" @click="deleteData(index)" :disabled="disabledStatus" type="text"
                        icon="el-icon-delete"></el-button>
                    </div>
                  </div> -->


                  <!-- 专业 -->
                  <!-- 当用户点击修改志愿时，ID不存在，所以需要加上ind -->
                  <!-- <div class="specialty-box" v-for="(ite, ind) in item.WishSpecialtyList" :key="String(ind) + ite.ID">

                    <div class="specialty-name">
                      <div class="audiot_style1">
                        {{ ite.SpecialtyName }}
                      </div>
                      <el-tag v-if="ite.LearnSubjectAssessment" size="mini">学科评估{{ ite.LearnSubjectAssessment
                      }}</el-tag>
                      <el-tag v-if="ite.SpecialtyRank" size="mini">专业排名{{ ite.SpecialtyRank }}</el-tag>
                    </div>
                    <div class="EnrollmentBox" v-if="ite.EnrollmentPercent">
                      {{
                        ite.EnrollmentPercent +
                        { 0: "%", 1: "" }[$ls.get("searchData").wishType]
                      }}
                    </div>
                    <div class="specialty-icon">
                      <span v-show="!disabledStatus" class="arrow-icon">
                        <i v-if="ind !== 0" class="el-icon-arrow-up" @click="caretTopSpecialty(index, ind)"></i>
                        <i v-if="ind !== item.WishSpecialtyList.length - 1" class="el-icon-arrow-down"
                          @click="caretBottomSpecialty(index, ind)"></i>
                      </span>
                      <el-button @click="deleteDataSpecialty(index, ind)" :disabled="disabledStatus" type="text"
                        icon="el-icon-delete"></el-button>
                    </div>
                  </div> -->
                </el-col>
              </el-row>
            </div>
            <div v-show="insertBlock" class="insert">
              <span class="insert-button" @click="insertWish(listData.length)">插入</span>
            </div>
          </div>
          <div class="list-btn-box">
            <el-button class="list-btn" type="primary" @click="saveList">保存志愿表</el-button>
            <!-- <div class="hide-node mxh80">
              <el-alert title="注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站；
                  申明：智能推荐是国内唯一一家使用当年招生计划和参考历年录取数据的志愿模拟填报平台，
                  参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。"
                type="warning" :closable="false" />
            </div> -->
          </div>
        </div>
        <div v-else class="defultbox">暂无志愿...</div>
      </div>
    </div>
    <floor-sort v-model="showFloor" :open-index="openIndex" :total-length="totalLength"
      v-on="{ dragChange: dragChange, sortChange: sortChange }"></floor-sort>
  </div>
</template>
<script>
import API from '@/api/config'
import sortable from 'sortablejs'
import { customClone } from '@/utils/index'
import { setTimeout } from "timers";
import { getStore } from "@/utils/utils";
// import { WishSave } from "@/api/common";
// import { NewExamTableHeadYear } from "@/api/newEntrance";
import FloorSort from '../custom-components/FloorSort.vue';
export default {
  components: {
    FloorSort
  },
  data() {
    return {
      // boxHeight: "0px",
      // listHeight: "0px",
      subnum: 0,
      flog: false,
      listData: [],
      inputvalue: "",
      PlanYear: "",
      // 插入排序
      moveText: '移动',
      insertBlock: false,
      disabledStatus: false,
      // 快速排序
      showFloor: false,
      openIndex: 0,
      totalLength: 0
    };
  },
  mounted() {
    this.show();
    this.getNewExamTableHeadYear();
    this.$nextTick(() => {
      this.dragBox()
    })
  },
  methods: {
    // 拖拽结束后的回调
    sortChange(params, callback) {

      let temp = this.listData.splice(params.oldIndex, 1)[0]

      this.listData.splice(params.newIndex, 0, temp)

      this.$ls.set("data", this.listData)

      // 显示区域定位到拖拽后元素新的位置

      let ele_box = document.getElementById('drag_box')

      // 渲染完后再定位才能准确
      this.$nextTick(() => {
        ele_box.childNodes[params.newIndex].scrollIntoView()

        this.showFloor = false
        callback()
      })
    },
    // 楼层组件回调
    dragChange(index) {
      // index 为索引参数
      let ele_box = document.getElementById('drag_box')

      ele_box.childNodes[Number(index)].scrollIntoView()
    },

    // 激活楼层排序
    openFloorSort(index) {
      this.showFloor = true
      this.openIndex = index
      this.totalLength = this.listData.length
    },
    // 插入志愿
    insertWish(index) {
      let selected = this.listData.filter(item => {
        return item.checkFlag // item.checkFlag为true时，就会被筛选出来
      })

      selected = selected.map(item => {
        return customClone(item) // 深拷贝已选中的志愿项
      })

      this.listData.forEach(item => { // 将大数组中被选中的项，标记状态
        if (item.checkFlag) {
          item.sortStatus = true
        }
      })

      /**
       *  将选中的子数组插入到总数组的指定位置
       * */

      // 先从指定位置将数组切成两段小数组，然后再将三个数组合并起来（slice方法不会改变原数组）

      let temp1 = this.listData.slice(0, index)
      let temp2 = this.listData.slice(index)

      this.listData = temp1.concat(selected).concat(temp2)


      // 清除标记的项（标记项是重复的，所以要清除）
      // （这里不能用循环的方式删除，因为在循环删除的过程中，数组的索引是在动态变化，会导致删不干净）
      this.listData = this.listData.filter(item => {
        item.checkFlag = false // 取消所有勾选状态
        return !item.sortStatus
      })

      this.disabledStatus = false
      this.insertBlock = false

      this.moveText = '移动'
    },
    // 点击移动排序按钮
    moveSort() {
      if (this.moveText === '移动') {

        let flag = this.listData.some(item => {
          if (item.checkFlag) return true
        })

        if (!flag) { // 确保有勾选项
          this.$message({
            type: 'warning',
            message: '未勾选志愿！',
            showClose: true
          })
          return
        }

        this.disabledStatus = true // 禁用删除 选择等操作
        this.insertBlock = true // 展示插入按钮

        this.moveText = '取消'
      } else {
        this.disabledStatus = false
        this.insertBlock = false

        this.moveText = '移动'
      }
    },

    // 拖拽初始化元素
    dragBox() {
      let id_box = document.getElementById('drag_box')
      if (id_box) {
        let dragConfig = {
          animation: 300,
          ghostClass: 'preview-class',
          filter: '.disabledDrag',
          onStart: () => { },
          onEnd: ({ newIndex, oldIndex }) => {
            let tempItem = this.listData.splice(oldIndex, 1)[0]
            this.listData.splice(newIndex, 0, tempItem)

            this.$ls.set("dataList", this.listData);
          }
        }
        // dragConfig.disabled = true
        sortable.create(id_box, dragConfig)
      }
    },

    show() {
      // console.log(document.documentElement.clientHeight, 'document.documentElement.clientHeight')
      // this.boxHeight = document.documentElement.clientHeight - 50 + "px";
      // this.listHeight = document.documentElement.clientHeight - 215 + "px";
      const Arr = this.$ls.get("dataList");

      this.listData = [];
      if (Arr && Arr.length > 0) {
        this.subnum = Arr.length;
        // this.listData = Arr;

        this.listData = Arr.map(item => {
          this.$set(item, 'checkFlag', false)
          this.$set(item, 'sortStatus', false)
          return item
        })

        setTimeout(() => {
          this.search();
        }, 100);
      } else {
        this.listData = [];
        this.subnum = 0;
      }

      this.dragBox()
    },
    loginDialogActivate() {
      this.$router.push('/login')
    },
    getScoreLow(arr) {

      return arr.map(item => {
        return {
          Year: item.Year,
          ScoreLow: item.ScoreLow,
          RankLow: item.RankLow
        }
      })

      // let data = {};
      // let flag = true;
      // arr.forEach((item) => {
      //   if (item.ScoreLow && flag) {
      //     data = {
      //       ScoreLowYear: item.Year,
      //       ScoreLow: item.ScoreLow,
      //       RankLow: item.RankLow,
      //     };
      //     flag = false;
      //   }
      // });
      // return data;
    },
    getNewExamTableHeadYear() {
      API.Wish.NewExamTableHeadYear({
        ProvinceId: this.$ls.get("searchData").ProvinceId,
        SelectExamSubjects: this.$ls.get("searchData").SelectExamSubjects,
      }).then((res) => {
        if (res.code === 0) {
          this.PlanYear = res.data.PlanYear;
        }
      });
    },
    saveList() {

      let temp = getStore('LoginData')

      if(!temp) {
        this.$message.info('请先登录！')
        return
      }

      this.listData.forEach((ele, index) => {
        ele.ScoreList = []
        if (ele.EnrollmentList && ele.EnrollmentList.length > 0) {
          ele.ScoreList = this.getScoreLow(ele.EnrollmentList)
        }
        ele.WishCode = String(index + 1);

        // *********
        ele.GroupCode = ele.GroupCode || null
        ele.ExamCondition = ele.ExamCondition || null

        ele.SchoolId = ele.SchoolId || null
        ele.SchoolName = ele.SchoolName || null
        ele.SchoolCode = ele.SchoolCode || null
        ele.PlanPersons = ele.PlanPersons || null
        ele.LearningYears = ele.LearningYears || null
        ele.LearningCost = ele.LearningCost || null
        ele.ScoreLowYear = ele.ScoreLowYear || null
        ele.ScoreLow = ele.ScoreLow || null
        ele.RankLow = ele.RankLow || null


        ele.Ranking =  ele.Ranking || null
        ele.RankingScore =  ele.RankingScore || null
        ele.RankingSort = ele.RankingSort || null
        ele.RankingType = ele.RankingType || null


        ele.WishSpecialtyList.forEach((item, inse) => {
          item.Sort = inse + 1;
          item.Persons = item.PlanPersons || item.Persons || null

          item.Code = item.Code || null
          item.SpecialtyCode = item.SpecialtyCode || null
          item.SpecialtyRank = item.SpecialtyRank || null
          item.SpecialtyName = item.SpecialtyName || null
          item.EnrollmentSpecialty = item.EnrollmentSpecialty || null

          item.LearningYears = item.LearningYears || null
          item.LearningCost = item.LearningCost || null
          item.ScoreLowYear = item.ScoreLowYear || null
          item.ScoreLow = item.ScoreLow || null
          item.RankLow = item.RankLow || null
          item.EnrollmentPercent = ((this.$ls.get('searchData').wishType === 0)?item.EnrollPercent : item.ForecastScore)
          item.LearnSubjectAssessment = item.LearnSubjectAssessment || null

          item.ScoreList = []
          if (item.EnrollmentList && item.EnrollmentList.length > 0) {
            item.ScoreList = this.getScoreLow(item.EnrollmentList)
          }

        });
      });
      const SelectExamSubject = this.$ls.get("searchData").SelectExamSubjects;
      const SelectExamSubjects = SelectExamSubject.map((item) => {
        item.SelectExamSubjects = item.SelectExamSubject;
        return item;
      });
      const data = {
        ID: this.$ls.get("searchData").ID,
        OrgId: JSON.parse(getStore('LoginData')).OrgId,
        UserId: JSON.parse(getStore('LoginData')).UserId,
        IsNewExam: true,
        ExamProvinceId: this.$ls.get("searchData").ProvinceId,
        ExamProvinceName: this.$ls.get("searchData").ProvinceName,
        WishWay: this.$ls.get("searchData").WishWay,
        WishType: this.$ls.get("searchData").wishType,
        Score: this.$ls.get("searchData").Score,
        ScoreEqual: this.$ls.get("searchData").ScoreEqual,
        RankLow: this.$ls.get("searchData").Rank,
        ScoreDiff: this.$ls.get("searchData").ScoreDiff,
        BatchId: this.$ls.get("searchData").BatchId,
        BatchName: this.$ls.get("searchData").BatchName,
        SelectSubjectList: SelectExamSubjects,
        Year: this.$ls.get("searchData").Year,
        PlanYear: this.PlanYear,
        WishSchoolList: this.listData,
      };
      API.User.SaveWish(data).then((res) => {
        if (res.code === 0) {
          this.$ls.remove("dataList");
          this.$ls.remove("data");
          let routerUrl = this.$router.resolve({
            path: "/wish-result",
            query: {
              WishId: res.data,
            },
          });
          // this.$emit("listShowChange");
          window.open(routerUrl.href);
        }
      });
    },
    handBtn() {
      this.flog = !this.flog;
      if (!this.flog) {
        this.disabledStatus = false
        this.insertBlock = false

        this.moveText = '移动'
      }
    },
    caretTop(index) {
      const data = this.listData[index - 1];
      this.listData[index - 1] = this.listData[index];
      this.listData[index] = data;
      this.resetData();
    },
    caretBottom(index) {
      const data = this.listData[index + 1];
      this.listData[index + 1] = this.listData[index];
      this.listData[index] = data;
      this.resetData();
    },
    deleteData(index) {
      this.listData.splice(index, 1);
      // const index = this.listData.findIndex(ele=>ele.sqcode === item.sqcode);
      this.resetData();
      this.$emit("tableList");
    },
    caretTopSpecialty(index, ind) {
      const data = this.listData[index].WishSpecialtyList[ind - 1];
      this.listData[index].WishSpecialtyList[ind - 1] =
        this.listData[index].WishSpecialtyList[ind];
      this.listData[index].WishSpecialtyList[ind] = data;
      this.resetData();
    },
    caretBottomSpecialty(index, ind) {
      const data = this.listData[index].WishSpecialtyList[ind + 1];
      this.listData[index].WishSpecialtyList[ind + 1] =
        this.listData[index].WishSpecialtyList[ind];
      this.listData[index].WishSpecialtyList[ind] = data;
      this.resetData();
    },
    deleteDataSpecialty(index, ind) {
      if (this.listData[index].WishSpecialtyList.length === 1) {
        this.listData.splice(index, 1);
      } else {
        this.listData[index].WishSpecialtyList.splice(ind, 1);
      }
      this.resetData();
      this.$emit("tableList");
    },
    resetData() {
      this.$ls.set("dataList", this.listData);
      this.show();
      // this.search();
    },
    search() {
      //translateText为从vuex穿过来的对话内容；
      // inputvalue为搜索框中的value
      // 获取所有对话内容的dom节点
      var audiot_style = document.getElementsByClassName("audiot_style");
      var audiot_style1 = document.getElementsByClassName("audiot_style1");
      var translateText = this.listData;
      var inputvalue = this.inputvalue;
      var List = [];
      // 遍历所有对话文本内容
      for (let i = 0; i < translateText.length; i++) {
        audiot_style[i].innerHTML = translateText[i].SchoolName;
        // 当对话内容中有包含搜索框中的字符串时
        if (translateText[i].SchoolName.indexOf(inputvalue) >= 0) {
          // 先将包含关键字的对话内容拆分为数组
          var values = translateText[i].SchoolName.split(inputvalue);
          // 然后再以一段设置了css样式的标签为分隔符,将数组拼接为字符串
          // 再赋值给对应的dom,让其节点的innerhtml为这个字符串
          audiot_style[i].innerHTML = values.join(
            '<span style="color:red;">' + inputvalue + "</span>"
          );
        }
        List = List.concat(translateText[i].WishSpecialtyList);
      }
      for (let a = 0; a < List.length; a++) {
        if (List[a].SpecialtyName.indexOf(inputvalue) >= 0) {
          // 先将包含关键字的对话内容拆分为数组
          var values = List[a].SpecialtyName.split(inputvalue);
          // 然后再以一段设置了css样式的标签为分隔符,将数组拼接为字符串
          // 再赋值给对应的dom,让其节点的innerhtml为这个字符串
          audiot_style1[a].innerHTML = values.join(
            '<span style="color:red;">' + inputvalue + "</span>"
          );
        } else {
          audiot_style1[a].innerHTML = List[a].SpecialtyName;
        }
      }
    },

    // 计算为预测分还是滤去概率
    countLevelValue(item) {
      let temp = this.$ls.get("searchData")
      return temp.wishType === 0 ? ('录取概率：' + item.EnrollmentPercent + '%') : ('预测分：' + item.EnrollmentPercent)
    }
  },
};
</script>
<style lang="less" scoped>
.main-box {
  position: fixed;
  z-index: 999;
  bottom: 0px;
  right: 0px;
}

.list-box {
  background: #fff;
  width: 0px;
  transition: width 0.5s;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.flogright {
  width: 0px;
  border: none;
}
.flogleft {
  width: 900px;
  border: 1px solid #e5e5e5;
}

.sub-box {
  display: flex;
}

.btn-box {
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  font-size: 18px;
  background: rgba(var(--themecolor),1);
  color: #fff;
  text-align: center;

  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}

.sublist {
  height: 100%;
  padding: 0 30px 0;
  box-sizing: border-box;

  .list-title {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .center-box {
    text-align: right;
    .move-button {
      padding: 0;
      // margin-right: 20px;
    }
  }

  .list-body {
    height: calc(100% - 140px);
    width: 100%;
    overflow-y: scroll;
    padding: 10px;

    .list-mes {
      margin-bottom: 10px;

      .sort-box {
        font-size: 18px;
        display: flex;
        align-items: center;
        height: 65px;

        span {
          display: inline-block;
          width: 50px;
          line-height: 30px;
        }

        i {
          cursor: pointer;
        }

        i:hover {
          color: rgba(var(--themecolor),0.75);
        }
      }
    }
  }

  .insert {
    padding: 5px 0;
    margin-bottom: 5px;
    text-align: center;

    box-shadow: 0 0 10px #ff8787 inset;

    cursor: pointer;
    .insert-button {
      color: rgba(var(--themecolor),0.75);
    }
  }

  /* 志愿项布局样式 */
  .wish-box {
    // display: grid;
    // grid-template-rows: 60px auto;
    margin-bottom: 10px;
  }

  .topbar {
    padding-bottom: 1px;
    margin-bottom: 10px;
    border-bottom: 2px solid red;

    .floor-sort {
      margin: 0 10px 0 40px;
    }
    .delete-button {
      padding: 9px 15px;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 180px 1fr;

    .left {
      font-size: 13px;
      .audiot_style, .level-value {
        font-size: 16px;
        color: #0671fd;
      }
    }

    .right {
      padding-left: 20px;
      border-left: 1px solid #e0e0e0;
      .specialty-header {
        display: grid;
        grid-template-columns: 100px 80px 1fr;

        padding: 0 5px;
        margin-bottom: 10px;
      }

      .specialty-box {
        
        display: grid;
        grid-template-columns: 100px 80px 1fr 80px;
        align-items: center;

        line-height: 1.5;
        padding: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        color: #808080;
        letter-spacing: 1px;
        background-color: rgba(201, 229, 253, 0.3);

        .specialty-icon {
          text-align: center;
          display: grid;
          grid-template-columns: 50px 1fr;
          align-items: center;
        }
      }
    }
  }

  .operate-sort {
    // padding: 0 10px;
    display: flex;
    align-items: center;

    background-color: rgba(204, 204, 204, 0.35);

    .check {
      margin-left: 20px;
    }

    .top-drag {
      flex-grow: 1;
      padding: 8px 0;
      margin-left: 20px;
      cursor: pointer;
      user-select: none;
      font-style: italic;
    }

    .disabeldStyle {
      color: #ccc;
    }
  }

  /* 设置滚动条的样式 */
  .list-body::-webkit-scrollbar {
    width: 4px;
  }

  /* 滚动槽 */
  .list-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  /* 滚动条滑块 */
  .list-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  .list-btn-box {
    width: calc(100% - 100px);
    position: absolute;
    // bottom: 20px;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    z-index: 1;

    .list-btn {
      width: 100%;
      font-size: 18px;
      // margin-bottom: 20px;
    }
  }
}

.defultbox {
  text-align: center;
  margin: 100px auto;
  font-size: 20px;
  color: rgba(var(--themecolor),0.75);
}

.preview-class {
  background-color: rgba(205, 202, 202, 0.3);
  // color: #5c8af6;
}
</style>
<style lang="less">
.el-button--text{
  color: rgba(var(--themecolor),0.75);
}
</style>