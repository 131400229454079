<template>
  <div class="app-container">
    <!--筛选 -->
    <serach @tableList="serachdata" @getWishWay="getWishWay" v-on="$listeners"></serach>
    <!-- 列表 -->
    <!-- 选择志愿列表 -->
    <!-- 专业院校组 -->
    <template v-if="$ls.get('searchData').WishWay === 2">
      <!--<div class="tips">注：以下为模拟参考数据，准确数据请以官方公布为准</div>-->

      <table-listWD v-if="$ls.get('searchData').wishType === 1" @tableList="tableList" ref="tableList"></table-listWD>
      <table-list v-if="$ls.get('searchData').wishType === 0" @tableList="tableList" ref="tableList"></table-list>
      <select-list v-on="$listeners" @tableList="selectList" ref="selectList"></select-list>
    </template>
    <!-- 院校专业组 -->
    <template v-if="$ls.get('searchData').WishWay === 1">
      <!--<div class="tips">注：以下为模拟参考数据，准确数据请以官方公布为准</div>-->
      <sqtableListWD v-if="$ls.get('searchData').wishType === 1" @tableList="tableList" ref="tableList"></sqtableListWD>
      <sqtableList v-if="$ls.get('searchData').wishType === 0" @tableList="tableList" ref="tableList"></sqtableList>
      <sqselectList v-on="$listeners" @tableList="selectList" ref="selectList"></sqselectList>
    </template>
  </div>
</template>
<script>
import serach from "./serach";
import TableList from "./tablelist";
import sqtableList from "./sqtablelist";
import selectList from "./selectlist";
import sqselectList from "./sqselectlist";
import TableListWD from "./tablelistWD";
import sqtableListWD from "./sqtablelistWD";
export default {
  name: "newentrance-index",
  components: {
    serach,
    TableList,
    sqtableList,
    selectList,
    sqselectList,
    TableListWD,
    sqtableListWD
  },
  data() {
    return {
      WishWay: this.$ls.get('searchData').WishWay,
      wishType:this.$ls.get('searchData').wishType
    };
  },
  provide() {
    return {
      getScrollTop: this.getScrollTop,
      setScrollTop: this.setScrollTop
    }
  },
  // mounted() {
  //   // this.getWishWay();
  // },
  mounted() {
    window.addEventListener("beforeunload", () => {
      this.$ls.remove("dataList");
      this.$ls.remove("data");
    });
  },
  beforeRouteLeave(to, from, next) {
    const dataList = this.$ls.get("dataList");
    const data = this.$ls.get("data");
    if ((dataList && dataList.length > 0) || (data && data.length > 0)) {
      const flog = confirm("离开该页面将删除缓存的志愿数据, 是否继续?", "提示");
      if (flog) {
        this.$ls.remove("dataList");
        this.$ls.remove("data");
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    getWishWay() {
      this.WishWay = this.$ls.get('searchData').WishWay;
      this.wishType = this.$ls.get('searchData').wishType;
    },
    selectList() {
      this.$refs.tableList.getLsdata();
    },
    tableList() {
      this.$refs.selectList.show();
    },
    serachdata(data) {
      this.tableList();
      this.$refs.tableList.getTableData(data);
    },
    getScrollTop(){
      return document.documentElement.scrollTop
    },
    setScrollTop(val){
      document.documentElement.scrollTop = val
    },
  },
};
</script>
<style lang="less" scoped>
.app-container {
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 14px;
  color: #606266;
  .tips{
    padding-top: 2rem;
    color: #F56C6C;
    letter-spacing: 2px;
    font-size: 1rem;
  }
}
</style>