<template>
  <el-dialog :visible.sync="batchTable" width="36rem" append-to-body center class="batch-dialog">
    <span>
      <el-table :data="BatchData.BatchList" style="width: 100%">
        <el-table-column label="报考批次" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.BatchName }}</span>
            <el-tag v-show="scope.row.Recommend" type="danger" size="mini"
              >推荐</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          :label="BatchData.Year + BatchData.SelectSubject + '批次控制线'"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.Score }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleClose(scope.row)"
              :id="scope.row.BatchId"
              :disabled="!scope.row.IsEnroll"
              >开始</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </span>
    <span slot="footer" class="dialog-footer">{{ Remark }}</span>
  </el-dialog>
</template>
<script>
import API from '@/api/config'
// import { NewExamBatchList } from "@/api/newEntrance";
export default {
  props: {
    ProvinceId: {
      type: String,
      default: "",
    },
    Year: Number,
    wishType: Number,
    ProvinceName: String,
    ExamSubject: Array,
    Score: Number,
    Remark: String,
  },
  data() {
    return {
      batchTable: false,
      BatchData: {},
      // batch: "",
    };
  },
  mounted() {
    // this.getEstimateScoreYear()
    
  },
  methods: {
    show(val) {
      // this.batch = val;
      this.batchTable = true;
      this.getNewExamBatchList();
    },
    getNewExamBatchList() {
      //  let SelectExamSubjects = [];
      //   this.ExamSubject.forEach(element => {

      //   });
        
      API.Wish.NewExamBatchList({
        ProvinceId: this.ProvinceId,
        SelectExamSubjects: this.ExamSubject,
        Score: this.Score,
      }).then((res) => {
        if (res.data) {
          this.BatchData = res.data;
        }
      });
    },
    handleClose(row) {
      const searchData = {
        EducationLevel: row.EducationLevel,
        
        BatchId: row.BatchId,
        BatchName: row.BatchName,
        Rank: this.BatchData.Rank,
        ScoreEqual: this.BatchData.ScoreEqual,
        // 1院校专业组 2专业院校组
        WishWay: row.WishWay,
        ProvinceId: this.ProvinceId,
        ProvinceName: this.ProvinceName,
        ScoreEqual: this.BatchData.ScoreEqual,
        Score: this.Score,
        wishType: this.wishType,
        ScoreDiff: this.Score - row.Score,
        SelectExamSubjects: this.ExamSubject,
        Year: this.Year,
        
      };

      if (!this.$ls.get("searchData")) {
        this.$ls.set("searchData", searchData);
      } else {

          this.$ls.remove("dataList");
          this.$ls.remove("data");
          this.$ls.set("searchData", searchData);

        // if (row.BatchId !== this.$ls.get("searchData").BatchId) {
        //   this.$ls.remove("dataList");
        //   this.$ls.remove("data");
        //   this.$ls.set("searchData", searchData);
        //   // if (this.batch) {
        //   //   setTimeout(() => {
        //   //     this.$emit("batchChange");
        //   //   });
        //   // }
        // }
      }

      this.batchTable = false;
      this.$emit('batchChange')
      

      // if(this.wishType ==='0'){
      // this.$router.push("/newentrance/index");
      // }else{
      // this.$router.push("/newentrance/indexWD");
      // }
      
      // this.$emit("listShowChange", examdata);
    },
    // getEstimateScoreYear () {
    //   API.Wish.WishYear().then((res) => {
    //     this.$ls.set("WishYear", res.data.Year);
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.el-dialog .el-button:first-child {
  margin-left: 0;

  &:hover {
    color: #fff;
  }
}
</style>
<style lang="less">
.batch-dialog .el-button--danger{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.batch-dialog .el-button--danger:focus, .batch-dialog .el-button--danger:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
  
}
.batch-dialog .el-tag.el-tag--danger{
  background-color: rgba(var(--themecolor),0.2);
  border-color: rgba(var(--themecolor),0.2);
  color: rgba(var(--themecolor),1);
  margin-left: 5px;
}
</style>